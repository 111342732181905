const initialState = { 
    loading: false
}

export function preloader(state = initialState, action) {
    switch (action.type) {
        case 'LOADING_STARTED':
            return { loading:  true };
        case 'LOADING_FINISHED':
            return { loading: false };
        default:
            return state;
    }
}