import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { questionnaire } from './helpers';
import { submitQuestionnaire } from './actions';
import { Header, Footer, ProgressBar } from './components';

import Container from '@material-ui/core/Container';
import { Grid, Paper, Box, Button, Select, MenuItem, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    middle: {
        backgroundColor: '#F0F0F0',
        //minHeight: '92vh',
        //height: 750,
        //paddingTop: 24,
        paddingBottom: 24,
        margin: 0,
        width: '100%'
    },
    headers: {
        width: 320,
        backgroundImage: 'url("/spell_black_background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        paddingTop: 24,
        marginRight: -16
    },
    text: {
        color: '#000',
        fontFamily: 'Arial',
        textAlign: 'left',
        fontSize: 14
    },
    psc: {
        fontSize: 20,
        fontFamily: 'Muna'
    },
    disclaimerText: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
    button: {
        backgroundColor: '#B3DFE5',
        '&:hover': {
            backgroundColor: '#D9B800',
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    error: {
        backgroundColor: '#B74956',
        padding: '8px 16px',
        width: 275,
        textAlign: 'center'
    }
}));

function Calculator() {
    const[answeredQuestionnaire, setAnsweredQuestionnaire] = useState(result => {
        result = [];
        for (var i = 0; i < questionnaire.length; i++) {
            result.push({question: questionnaire[i].question, answered: "none"});
        }
        return result;
    });

    const[page, setPage] = useState(0);
    const[questionnaireError, setQuestionnaireError] = useState();

    function setInput(key, value) {
        let result = [...answeredQuestionnaire];
        result[key].question = questionnaire[key].question;
        result[key].answered = value;
        setAnsweredQuestionnaire([ ...result ]);
    }

    function setMultiInput(key, value) {
        let result = [...answeredQuestionnaire];
        result[key].question = questionnaire[key].question;
        if (result[key].answered === "none") {
            result[key].answered = [];
        }
        result[key].answered.includes(value) ? result[key].answered.splice(result[key].answered.indexOf(value), 1) : result[key].answered.push(value);
        if (result[key].answered.length === 0) {
            result[key].answered = "none";
        }
        setAnsweredQuestionnaire([ ...result ]);
    }

    function paginate(value) {
        let nextPage = page + value
        if (0 <= nextPage && nextPage < questionnaire.length) {
            setPage(nextPage);
        }
    }

    function validateInput() {
        return answeredQuestionnaire.reduce((accumulator, currentValue, currentIndex) => accumulator && (currentValue.answered !== "none" || currentIndex == questionnaire.length - 1))
    }

    const dispatch = useDispatch();
    const questionnaireResult = useSelector(state => state.psc.questionnaireResult);
    const history = useHistory();

    function handleSubmitQuestionnaire(e) {
        if (validateInput()) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6Le5L8oZAAAAABr0n8KZrmF9E0Ch6b1nJwvCT3vZ', {action: 'questionnaire'}).then(function(token) {
                    dispatch(submitQuestionnaire(answeredQuestionnaire, token));
                });
            });
        } else {
            setQuestionnaireError("Please answer all the questions!");
        }
    }

    useEffect(() => {
        if (questionnaireResult && questionnaireResult !== '') {
            history.push("/results");
        }
    }, [questionnaireResult]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const classes = useStyles();

    return (
        <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 32 }}>
            <Header />
            <Grid container spacing={2} direction="column" justify="flex-start" alignItems="center" className={classes.middle}>
                <Grid container item direction="column" justify="flex-start" alignItems="center" className={classes.headers}>
                    <Grid item style={{ paddingBottom: 0, paddingTop: 24 }}><Box className={classes.psc}>PRODUCT SUCCESS</Box></Grid>
                    <Grid item style={{ paddingTop: 0 }}><Box className={classes.psc}>CALCULATOR</Box></Grid>
                    <Grid item><Box style={{ fontSize: 12, fontFamily: 'Arial', marginTop: 12, marginBottom: 8, textAlign: 'center' }}>Evaluate your product idea by certain attributes and <i>teamofteams.io</i> algorithm</Box></Grid>
                    <Grid item>
                        <Box className={classes.disclaimerText} style={{ marginTop: 12 }}>PROGRESS</Box>
                        <ProgressBar current={page / 2} total={questionnaire.length / 2} />
                    </Grid>
                </Grid>
                <Grid item style={{ width: 320 }}>
                    <Box className={classes.disclaimerText}>{questionnaire[page].question}</Box>                    
                </Grid>
                <Grid item>
                    <Select variant="outlined" style={{ width: 290 }} value={answeredQuestionnaire[page].answered} onChange={event => setInput(page, event.target.value)}>
                        <MenuItem value="none" disabled>Please make a selection...</MenuItem>
                        {questionnaire[page].answerOptions.map((element, index) => (<MenuItem key={index} value={element}>{element}</MenuItem>))}
                    </Select>
                </Grid>
                <Grid item style={{ width: 320 }}>
                    <Box className={classes.disclaimerText}>{questionnaire[page + 1].question}</Box>                    
                </Grid>
                <Grid item>
                    { !!questionnaire[page + 1].multiSelect ?
                        <FormControl component="fieldset" style={{ marginLeft: 36 }}>
                            <FormGroup>
                                {questionnaire[page + 1].answerOptions.map((element, index) =>
                                    (<FormControlLabel control={<Checkbox onChange={event => setMultiInput(page + 1, element)} checked={answeredQuestionnaire[page + 1].answered.includes(element)} />} label={element} key={index} />))}
                            </FormGroup>
                        </FormControl>
                    :
                        <Select variant="outlined" style={{ width: 290 }} value={answeredQuestionnaire[page + 1].answered} onChange={event => setInput(page + 1, event.target.value)}>
                            <MenuItem value="none" disabled>Please make a selection...</MenuItem>
                            {questionnaire[page + 1].answerOptions.map((element, index) => (<MenuItem key={index} value={element}>{element}</MenuItem>))}
                        </Select>
                    }                    
                </Grid>
                { questionnaireError &&
                    <Grid item>
                        <Paper className={classes.error} elevation={3}><Box style={{color: 'white'}}>{questionnaireError}</Box></Paper>
                    </Grid>
                }
                <Grid item container spacing={2} direction="row" justify="center" alignItems="center" style={{ paddingTop: 16, margin: 0, width: '100%' }}>
                    { page === 0 ? 
                        <Grid item><Link to="/disclaimer-calculator" style={{ textDecoration: 'none' }}><Button variant="contained" className={classes.button}>Back</Button></Link></Grid>
                    :
                        <Grid item><Button variant="contained" className={classes.button} onClick={() => paginate(-2)}>Back</Button></Grid>
                    }
                    { page === (questionnaire.length - 2) ?
                        <Grid item><Button variant="contained" className={classes.button} onClick={handleSubmitQuestionnaire}>Submit</Button></Grid>
                    :
                        <Grid item><Button variant="contained" className={classes.button} onClick={() => paginate(2)}>Next</Button></Grid>
                    }                
                </Grid>
            </Grid>
            <Footer />
        </Container>
    );
}

export default Calculator;