import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Grid, CardMedia, Paper, Box, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SendSharpIcon from '@material-ui/icons/SendSharp';

import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { submitTimelineProductDetails } from './actions';
import { Header, Footer } from './components';

const useStyles = makeStyles((theme) => ({
    middle: {
        backgroundColor: '#F0F0F0',
        paddingBottom: 24,
        margin: 0,
        width: '100%'
    },
    headers: {
        width: 320,
        backgroundImage: 'url("/pte_background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        paddingTop: 24,
        marginRight: -16
    },
    text: {
        fontFamily: 'Helvetica Neue',
        fontSize: 12
    },
    recommendationText: {
        fontFamily: 'Helvetica Neue',
        fontSize: 14
    },
    psc: {
        fontSize: 20,
        fontFamily: 'Muna'
    },
    disclaimerText: {
        fontSize: 20,
        fontFamily: 'Muna',
        fontWeight: 'bold'
    },
    labelText: {
        fontSize: 15,
        fontFamily: 'Arial'
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    successRateArea: {
        backgroundColor: 'rgba(179, 224, 229, 0.58)',
        borderRadius: 6,
    },
    sprintArea: {
        backgroundColor: '#94A3B1',
        borderRadius: 6,
    },
    developmentArea: {
        borderRadius: 9,
        border: '1px dotted black',
        padding: 16,
        backgroundColor: '#F0F0F0',
    },
    productDetailsArea: {
        borderRadius: 9,
        border: '1px dotted black',
        padding: 16,
        backgroundColor: '#F0F0F0',
    },
    miniHeader: {
        backgroundColor: '#D9B800',
        color: '#000',
        padding: '4px 12px 4px 12px',
        fontFamily: 'Arial',
        fontSize: 20,
        borderRadius: 5
    },
    input: {
        backgroundColor: '#C0F4FD',
        color: '#000000',
        borderRadius: 6,
        width: '275px',
        '&:hover': {
            backgroundColor: '#C0F4FD',
        },
        '&.Mui-focused': {
            backgroundColor: '#C0F4FD',
        }
    },
    error: {
        backgroundColor: '#B74956',
        padding: '8px 16px',
        width: 275,
        textAlign: 'center'
    }
}));

function Timeline() {
    const [productDetailsState, setProductDetailsState] = useState({
        email: '',
        productName: '',
        productValue: '',
        productProblem: '',
        displaySendButton: false
    });

    function setInput(key, value) {
        setProductDetailsState({ ...productDetailsState, [key]: value });
    }

    const[resultsError, setResultsError] = useState();

    const timelineResult = useSelector(state => state.pte.timelineResult);
    const timelineEmailSent = useSelector(state => state.pte.timelineEmailSent);
    const dispatch = useDispatch();

    useEffect(() => {
        if (validateEmail(productDetailsState.email)) {
            setProductDetailsState({ ...productDetailsState, displaySendButton: true });
        } else {
            setProductDetailsState({ ...productDetailsState, displaySendButton: false });
        }
    }, [productDetailsState.email]);

    function validateEmail(email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    function handleSendResults(e) {
        if (validateEmail(productDetailsState.email) && productDetailsState.productName && productDetailsState.productValue && productDetailsState.productProblem) {
            dispatch(submitTimelineProductDetails(timelineResult.id, timelineResult.submitted_date, productDetailsState.email, concatenateProductDescription()));
        } else {
            setResultsError('Please fill in all the fields!');
        }
    }

    function concatenateProductDescription() {
        return 'As a customer of ' + productDetailsState.productName + ' I want to ' + productDetailsState.productValue + ' so that ' + productDetailsState.productProblem;
    }

    function renderDevSprints(numberOfSprints) {
        const sprints = [];
        for (let i = 0; i < numberOfSprints; i++) {
            sprints.push(
                <Grid item style={{ marginTop: 12 }}>
                    <Paper className={classes.successRateArea}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" style={{ width: 140, padding: 8 }}>
                            <Grid item><CardMedia image="/pte.png" component="img" style={{ width: 22, height: 22 }} /></Grid>
                            <Grid item style={{ paddingLeft: 16 }}><Box style={{ fontSize: 16, fontFamily: 'Arial' }}>Sprint {i + 1}</Box></Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        }
        return sprints;
    }

    var halfMonth = false;

    const monthLabels = [];
    function renderMonthMilestonesDevelopment(numberOfSprints) {
        const milestones = [];        
        milestones.push(<CardMedia image="/month_milestone.png" component="img" style={{ width: 8, height: 8, marginTop: 91 }} />);
        monthLabels.push(<Box style={{ fontSize: 12, fontFamily: 'Arial', paddingLeft: 4, paddingTop: 87 }}>Month&nbsp;1</Box>);
        for (let i = 3; i <= numberOfSprints; i += 2) {
            milestones.push(<CardMedia image="/month_milestone.png" component="img" style={{ width: 8, height: 8, marginTop: 91 }} />);
            monthLabels.push(<Box style={{ fontSize: 12, fontFamily: 'Arial', paddingLeft: 4, paddingTop: 82 }}>Month&nbsp;{Math.round(i / 2)}</Box>);
        }
        if (numberOfSprints % 2 == 0) {
            halfMonth = true;
        }
        return milestones;
    }

    const monthLabelsRollout = [];
    function renderMonthMilestonesRollout(numberOfSprints) {
        const milestones = [];
        milestones.push(<CardMedia image="/month_milestone.png" component="img" style={{ width: 8, height: 8, marginTop: 84, visibility: halfMonth ? '' : 'hidden' }} />);
        monthLabelsRollout.push(<Box style={{ fontSize: 12, fontFamily: 'Arial', paddingLeft: 4, paddingTop: 80, visibility: halfMonth ? '' : 'hidden' }}>Month&nbsp;{Math.round(numberOfSprints / 2) + 1}</Box>);
        return milestones;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const classes = useStyles();

    return (
        <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 32 }}>
            { timelineResult !== '' ? (<>
            <Header />
            <Grid container spacing={2} direction="column" justify="flex-start" alignItems="center" className={classes.middle}>
                <Grid container item direction="column" justify="flex-start" alignItems="center" className={classes.headers}>
                    <Grid item style={{ paddingBottom: 0, paddingTop: 24 }}><Box className={classes.psc}>PRODUCT TIMELINE</Box></Grid>
                    <Grid item style={{ paddingTop: 0 }}><Box className={classes.psc}>ESTIMATOR</Box></Grid>
                    <Grid item><Box className={classes.disclaimerText} style={{ marginTop: 24 }}>RESULTS*</Box></Grid>
                    <Grid item><Box style={{ fontSize: 12, fontFamily: 'Arial', marginTop: 8 }}>* can be sent to your email. Please check below</Box></Grid>
                </Grid>
                <Grid item style={{ marginRight: -16 }}>
                    <Paper className={classes.successRateArea}>
                        <Grid container direction="column" alignItems="center" style={{ width: 280 }}>
                            <Grid item style={{ paddingTop: 8 }}><Box style={{ fontSize: 50, fontFamily: 'Muna' }}>{timelineResult.results.number_of_sprints + 2} Sprints</Box></Grid>
                            <Grid item><Box style={{ fontSize: 18, fontFamily: 'Muna' }}>total recommended</Box></Grid>
                            <Grid item style={{ padding: 8, paddingLeft: 16 }}><Box className={classes.text}>Duration of 1 Sprint equals to 2 weeks. Total team capacity per Sprint: <Box component="span" style={{ color: '#D9B800', fontWeight: 'bold' }}>{ timelineResult.results.total_dev_capacity } man-days</Box> of pure development effort.</Box></Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item container direction="row" justify="flex-start" wrap="nowrap" alignItems="flex-start" style={{ width: 280, paddingTop: 8, paddingBottom: 0, marginRight: -16 }}>
                    <Grid item container direction="column" alignItems="center" style={{ width: 10 }}>
                        <Grid item style={{ backgroundImage: 'url("/red_line_top.png")', height: 4, width: 8 }}></Grid>
                        <Grid item style={{ backgroundImage: 'url("/red_line_middle.png")', height: 102, width: 8 }}></Grid>
                        <Grid item style={{ backgroundImage: 'url("/red_line_bottom.png")', height: 4, width: 8 }}></Grid>
                    </Grid>
                    <Grid item style={{ marginLeft: 32, marginRight: 32, marginTop: 8 }}>
                        <Paper className={classes.sprintArea}>
                            <Grid container direction="column" alignItems="center" style={{ width: 140, padding: 8 }}>
                                <Grid item style={{ paddingBottom: 4 }}><Box style={{ fontSize: 16, fontFamily: 'Arial' }}>Sprint 0</Box></Grid>
                                <Grid item><Box style={{ fontSize: 10, fontFamily: 'Arial', textAlign: 'center' }}>Detailed User Stories, environments and Engineering Team ramp up</Box></Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item style={{ backgroundImage: 'url("/timeline_months.png")', height: 110, width: 8 }}></Grid>
                    <Grid item alignItems="flex-start" justify="flex-start" style={{ width: 50, marginLeft: 8 }}>
                        <Box style={{ fontSize: 10, fontFamily: 'Arial' }}>approx. monthly timeline</Box>
                    </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-start" wrap="nowrap" alignItems="flex-start" style={{ width: 280, paddingTop: 0, paddingBottom: 0, marginRight: -16 }}>
                    <Grid item container direction="column" alignItems="center" style={{ width: 10 }}>
                        <Grid item style={{ backgroundImage: 'url("/yellow_line_top.png")', height: 4, width: 8 }}></Grid>
                        <Grid item style={{ backgroundImage: 'url("/yellow_line_middle.png")', height: 53 + timelineResult.results.number_of_sprints * 50, width: 8 }}></Grid>
                        <Grid item style={{ backgroundImage: 'url("/yellow_line_bottom.png")', height: 4, width: 8 }}></Grid>
                    </Grid>
                    <Grid item style={{ marginLeft: 16, marginRight: 16 }}>
                        <Paper className={classes.developmentArea}>
                            <Grid container direction="column" alignItems="center" style={{ width: 138 }}>
                                <Grid item style={{ paddingBottom: 4 }}><Box style={{ fontSize: 11, fontFamily: 'Arial' }}>Design, Development, Test</Box></Grid>
                                { renderDevSprints(timelineResult.results.number_of_sprints) }
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item style={{ backgroundImage: 'url("/timeline_months.png")', height: 61 + timelineResult.results.number_of_sprints * 50, width: 8 }}>
                        { renderMonthMilestonesDevelopment(timelineResult.results.number_of_sprints) }
                    </Grid>
                    <Grid item alignItems="flex-start" justify="flex-start">
                        { monthLabels }                        
                    </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-start" wrap="nowrap" alignItems="flex-start" style={{ width: 280, paddingTop: 0, paddingBottom: 0, marginRight: -16 }}>
                    <Grid item container direction="column" alignItems="center" style={{ width: 10 }}>
                        <Grid item style={{ backgroundImage: 'url("/green_line_top.png")', height: 4, width: 8 }}></Grid>
                        <Grid item style={{ backgroundImage: 'url("/green_line_middle.png")', height: 168, width: 8 }}></Grid>
                        <Grid item style={{ backgroundImage: 'url("/green_line_bottom.png")', height: 4, width: 8 }}></Grid>
                    </Grid>
                    <Grid item style={{ marginLeft: 32, marginRight: 32, marginTop: 12 }}>
                        <Paper className={classes.sprintArea}>
                            <Grid container direction="column" alignItems="center" style={{ width: 140, padding: 8 }}>
                                <Grid item style={{ paddingBottom: 4 }}><Box style={{ fontSize: 16, fontFamily: 'Arial' }}>Sprint {timelineResult.results.number_of_sprints + 1}</Box></Grid>
                                <Grid item><Box style={{ fontSize: 10, fontFamily: 'Arial', textAlign: 'center' }}>Product / Feature<br />Release</Box></Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.sprintArea} style={{ marginTop: 12, marginBottom: 12, backgroundColor: '#E37FBA' }}>
                            <Grid container direction="column" alignItems="center" style={{ width: 140, padding: 8 }}>
                                <Grid item style={{ paddingBottom: 4 }}><Box style={{ fontSize: 16, fontFamily: 'Arial' }}>Future</Box></Grid>
                                <Grid item><Box style={{ fontSize: 10, fontFamily: 'Arial', textAlign: 'center' }}>Adoption and Customer Feedback Processing</Box></Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item style={{ backgroundImage: 'url("/timeline_months.png")', height: 176, width: 8 }}>
                        { renderMonthMilestonesRollout(timelineResult.results.number_of_sprints) }
                    </Grid>
                    <Grid item alignItems="flex-start" justify="flex-start">
                        { monthLabelsRollout }                        
                    </Grid>
                </Grid>
                <Grid item style={{ marginRight: -16 }}>
                    <Paper className={classes.successRateArea}>
                        <Grid container direction="column" alignItems="center" style={{ width: 280, padding: 16 }}>
                            <Grid item><Box style={{ fontSize: 16 }}>You’ve earned a badge from<br />Team of Teams platform because you've created a product timeline estimate! Save your "ToT Scrum Master" status by proceeding with the "Brain Trainer" form below.</Box></Grid>
                            <Grid item style={{ marginTop: 16 }}><CardMedia image="/scrum_master_badge.png" component="img" /></Grid>                        
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item style={{ marginRight: -16 }}>
                    <Paper className={classes.productDetailsArea}>
                        { timelineEmailSent ? 
                            <Grid container direction="column" alignItems="center" style={{ width: 280 }}>
                                <Grid item><Box style={{ fontSize: 28, fontFamily: 'Muna' }}>THANK YOU</Box></Grid>
                                <Grid item><Box>Results were sent to your email address</Box></Grid>
                            </Grid>
                        :
                            <Grid container direction="column" alignItems="center" style={{ width: 280 }}>
                                <Grid item container spacing={2} direction="column" alignItems="center" style={{ width: 280 }}>
                                    <Grid item style={{ width: 280, textAlign: 'center' }}><Paper className={classes.miniHeader} elevation={0}>Brain Trainer</Paper></Grid>
                                    <Grid item><Box style={{ fontSize: 15 }}>To get this snapshot timeline to email, describe what value the product, corresponding to it, delivers according to the user story structure from your <b>customer's perspective</b>. Here is the example of it for the Team of Teams platform:</Box></Grid>
                                    <Grid item style={{ paddingTop: 0 }}><Box className={classes.text} style={{ fontStyle: 'italic', fontSize: 13 }}><b>As a customer of</b> Team of Teams digital platform <b>I want to</b> form partnerships based on common professional values and use platform accelerators to lift up my product ideas <b>so that</b> I can build great products and high performing teams!</Box></Grid>
                                </Grid>
                                <Grid item container direction="column" alignItems="flex-start" style={{ width: 280, paddingTop: 32 }}>
                                    <Grid item><Box className={classes.labelText}>As a customer of</Box></Grid>
                                    <Grid item>
                                        <TextField required id="productName" name="productName" variant="filled" placeholder="your product name" margin="dense"
                                            onChange={event => setInput('productName', event.target.value)} value={productDetailsState.productName}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px'}, maxLength: 64 }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}><Box className={classes.labelText}>I want to</Box></Grid>
                                    <Grid item>
                                        <TextField required id="productValue" name="productValue" variant="filled" placeholder="your value" margin="dense"
                                            onChange={event => setInput('productValue', event.target.value)} value={productDetailsState.productValue}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px' }, maxLength: 256 }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}><Box className={classes.labelText}>So that</Box></Grid>
                                    <Grid item>
                                        <TextField required id="productProblem" name="productProblem" variant="filled" placeholder="what problem resolved" margin="dense"
                                            onChange={event => setInput('productProblem', event.target.value)} value={productDetailsState.productProblem}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px' }, maxLength: 256 }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}><Box className={classes.labelText}>Get copy of your results</Box></Grid>
                                    <Grid item>
                                        <TextField required id="email" name="email" variant="filled" placeholder="your email" margin="dense"
                                            onChange={event => setInput('email', event.target.value)} value={productDetailsState.email}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px' }, maxLength: 64 }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true,
                                                endAdornment: productDetailsState.displaySendButton && (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={handleSendResults}><SendSharpIcon style={{ fill: 'black'}} /></IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    { resultsError &&
                                        <Grid item style={{ marginTop: 8 }}>
                                            <Paper className={classes.error} elevation={3}><Box style={{color: 'white'}}>{resultsError}</Box></Paper>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <Footer />
            </>) : <Redirect to='/' /> }
        </Container>
    );
}

export default Timeline;