import React from 'react';

import { CardMedia, CardContent } from '@material-ui/core';

export function ResultBar(props) {
    let status = [];
    if (props.rating === "red") {
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/indicator_red.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_future.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_future.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_future.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_future.png" component="img" />);
    } else if (props.rating === "yellow") {
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_current.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_current.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_current.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_future.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_future.png" component="img" />);
    } else if (props.rating === "green") {
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_past.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_past.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_past.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_past.png" component="img" />);
        status.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_past.png" component="img" />);
    }

    return (
        <CardContent style={{ display: 'flex', paddingTop: 4, paddingBottom: 12, paddingRight: 4 }}>
            { status }
        </CardContent>
    )
}