import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';

import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Grid, CardMedia, Box, TextField, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Paper, useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { joinWaitlist } from './actions';

const useStyles = makeStyles((theme) => ({
    input: {
        color: '#000000',
        borderRadius: 6,
        width: 280
    },
    button: {
        backgroundColor: '#B3DFE5',
        '&:hover': {
            backgroundColor: '#D9B800',
        },
        textTransform: 'none',
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
            width: 280
        }
    },
    expansionHeading: {
        fontFamily: 'Arial',
        fontSize: 20,
        fontWeight: 'bold'
    },
    hideBorder: {
        '&.MuiExpansionPanel-root:before': {
            display: 'none'
        },
        boxShadow: 'none'
    },
    calculator: {
        backgroundColor: '#F0F0F0',
        borderRadius: 11
    },
    booster: {
        backgroundColor: '#D9B800',
        color: '#fff',
        margin: '4px 0px 8px 8px',
        padding: '4px 12px 4px 12px',
        fontFamily: 'Helvetica Neue',
        borderRadius: 5
    },
    psc: {
        color: '#4A4A4A',
        margin: '0px 0px 0px 8px',
        fontSize: 16,
        fontFamily: 'Muna'
    },
    bottom: {
        margin: 0,
        width: '100%',
    },
    footerText: {
        color: '#000000',
        fontFamily: 'Arial',
        fontSize: 14,
    },
    productDetailsArea: {
        borderRadius: 9,
        border: '1px dotted black',
        padding: 8,
        backgroundColor: '#C8EAEF'
    },
    topHeader: {
        fontFamily: 'Roboto',
        fontSize: 45,
        color: '#4D4D4D',
        [theme.breakpoints.down('xs')]: {
            fontSize: 28
        }
    },
    middleText: {
        fontFamily: 'Roboto',
        fontSize: 30,
        color: '#4D4D4D',
        [theme.breakpoints.down('xs')]: {
            fontSize: 19
        }
    },
    subHeader: {
        fontFamily: 'Arial',
        fontSize: 20,
        color: '#00BAFF',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            fontWeight: 'normal'
        }
    },
    expandIcon: {
        backgroundColor: '#B3DFE5',
        '&:hover': {
            backgroundColor: '#D9B800',
        }
    },
    dailyMotivationArea: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderRadius: 11,
        color: '#FFFFFF',
        marginTop: 8,
        border: '1px dashed #D9B800',
    },
    dailyMotivationText: {
        fontFamily: 'Times New Roman',
        fontSize: 26,
        padding: 8,
        paddingLeft: 16,
        fontStyle: 'oblique',
        [theme.breakpoints.down('xs')]: {
            fontSize: 20
        }
    },
    slogan: {
        fontFamily: 'Arial',
        fontSize: 26,
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: 20
        }
    },
    rightColumn: {
        paddingTop: 128,
        padding: 32,
        paddingLeft: 64,
        paddingRight: 64,
        [theme.breakpoints.down('md')]: {
            paddingTop: 32
        }
    },
    middleSection: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'center'
        }
    },
    middleImageSection: {
        marginLeft: 16,
        [theme.breakpoints.down('sm')]: {
            marginTop: 16
        }
    }
}));

function Home() {
    const [homeFormState, setHomeFormState] = useState({
        email: '',
        quote: '',
        author: ''
    });

    function setInput(key, value) {
        setHomeFormState({ ...homeFormState, [key]: value });
    }

    function validateEmail(email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    const joinedWaitlist = useSelector(state => state.waitlist.joined);
    const dispatch = useDispatch();

    function handleJoinWaitlist(e) {
        if (validateEmail(homeFormState.email)) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6Le5L8oZAAAAABr0n8KZrmF9E0Ch6b1nJwvCT3vZ', {action: 'waitlist'}).then(function(token) {
                    dispatch(joinWaitlist(homeFormState.email, token))
                });
            });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        retrieveQuoteOfTheDay();
    }, []);

    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    function retrieveQuoteOfTheDay() {
        Storage.get('quote.json', { download: false }) 
            .then(url => {
                fetch(url)
                    .then(response => response.json())
                    .then(data => setHomeFormState({ ...homeFormState, quote: data.text, author: data.author }));
            })
            .catch(err => console.log(err));
    }

    return (
        <Container component="main" maxWidth={false} className="App" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid container direction="row" wrap="wrap-reverse" justify="center" alignItems="flex-end" style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
                <Grid item container style={{ backgroundColor: '#fff', minHeight: '100vh',  padding: 36, paddingBottom: 16, paddingTop: 24 }} direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={12} lg={8}>
                    <Grid item><Box className={classes.topHeader}>Find co-founder and develop trust with a help of Machine Learning</Box></Grid>
                    <Grid item style={{ marginTop: 16, marginBottom: 24 }}><Box className={classes.subHeader}>Matched by <u>mutual values</u>, driven by <u>digital tokens</u></Box></Grid>
                    <Grid item>
                        <ExpansionPanel className={classes.hideBorder}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0 }} IconButtonProps={{ classes: { root: classes.expandIcon }}} >
                                <Typography className={classes.expansionHeading}>Why do you need a co-founder or partner?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>According to many VCs, they would “never” invest in a first-time solo founder. Find others to share your journey, to gain perspective, mitigate risks, be in two places at once and cover your weaknesses. Building a team is the critical skill of any good entrepreneur, so start with co-founder using <em>teamofteams.io</em></Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item style={{ marginTop: 12 }}>
                        <ExpansionPanel className={classes.hideBorder}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ paddingLeft: 0 }} IconButtonProps={{ classes: { root: classes.expandIcon }}} >
                                <Typography className={classes.expansionHeading}>How to build trust with your partner?</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>Trust is fundamental in a healthy relationship. Whether you start or recover your relationship, the best way to maintain it - is to increase trust level consistently. <em>teamofteams.io</em> helps you in a friendly transparent way to digitize your trust progress via tokens and retrospectively improve your relationship based on selected values.</Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item style={{ marginTop: 32, marginBottom: 32, width: '100%' }}>
                        <CardMedia style={{ height: 1 }} image="/delimeter_horizontal.png" component="img" />
                    </Grid>
                    <Grid item container direction="row" justify="space-between" alignItems="center" className={classes.middleSection}>
                        <Grid item><Box className={classes.middleText}>Build and strengthen your partner engagement based on VALUES.<br /><br />Start with ONE, expand to MORE while improving.</Box></Grid>
                        <Grid item className={classes.middleImageSection}><CardMedia image="/values.png" component="img" style={{ width: 221, height: 349 }}/></Grid>
                    </Grid>
                    <Grid item style={{ marginTop: 32, marginBottom: 32, alignSelf: 'center' }}>
                        <CardMedia style={{ width: 76, height: 76}} image="/arrow_delimeter.png" component="img" />
                    </Grid>
                    <Grid item container direction="row" justify="space-between" alignItems="center" className={classes.middleSection} style={{ marginBottom: 24 }}>
                        <Grid item><Box className={classes.middleText}>Caliber while making progress. Earn trust and digital tokens to keep up your liquidity.</Box></Grid>                            
                        <Grid item className={classes.middleImageSection} style={{ marginRight: 16 }}><CardMedia image="/calibration.png" component="img" style={{ width: 204, height: 519 }}/></Grid>
                    </Grid>
                    { joinedWaitlist ?
                        <Grid item style={{ alignSelf: 'center' }}>
                            <Paper className={classes.productDetailsArea}>
                                <Box>Thank you for your interest. Stay tuned for further updates!</Box>
                            </Paper>
                        </Grid>
                    :
                        <Grid item container spacing={2} direction="row" justify="flex-start" alignItems="center" style={{ justifyContent: 'center' }}>
                            <Grid item>
                                <TextField required id="email" name="email" variant="outlined" placeholder="Enter your email"
                                    onChange={event => setInput('email', event.target.value)} value={homeFormState.email}
                                    inputProps={{ style: { textAlign: 'center', fontSize: 15, padding: '11px' }, maxLength: 64}}
                                    InputProps={{
                                        classes: {
                                            root: classes.input 
                                        },
                                        disableUnderline: true
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" className={classes.button} onClick={handleJoinWaitlist}>Join the waiting list!</Button>
                            </Grid>
                        </Grid>
                    }
                    <Grid item style={{ marginTop: 32, marginBottom: 32, width: '100%' }}>
                        <CardMedia style={{ height: 1 }} image="/delimeter_horizontal.png" component="img" />
                    </Grid>
                    <Grid item><Box className={classes.topHeader}>Try our free tools to boost your go to market and product development strategy</Box></Grid>
                    <Grid item container spacing={2} direction="row" justify="flex-start" alignItems="center" style={{ marginTop: 16 }}>
                        <Grid item>
                            <Link to="/disclaimer-calculator" style={{ textDecoration: 'none' }}>
                                <Paper className={classes.calculator} elevation={3}>
                                    <Grid container direction="row" wrap="nowrap" style={{ width: '240px', padding: '4px 0px 4px 0px' }}>
                                        <Grid item container direction="column" alignItems="flex-start" >
                                            <Grid item>
                                                <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
                                            </Grid>
                                            <Grid item><Box className={classes.psc}>PRODUCT</Box></Grid>
                                            <Grid item><Box className={classes.psc}>SUCCESS</Box></Grid>
                                            <Grid item><Box className={classes.psc}>CALCULATOR</Box></Grid>
                                        </Grid>
                                        <Grid item style={{ margin: '0px -6px 0px 0px' }}>
                                            <CardMedia image="/psc.png" component="img" />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/disclaimer-estimator" style={{ textDecoration: 'none' }}>
                                <Paper className={classes.calculator} elevation={3}>
                                    <Grid container direction="row" wrap="nowrap" style={{ width: '240px', padding: '4px 0px 4px 0px' }}>
                                        <Grid item container direction="column" alignItems="flex-start" >
                                            <Grid item>
                                                <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
                                            </Grid>
                                            <Grid item><Box className={classes.psc}>PRODUCT</Box></Grid>
                                            <Grid item><Box className={classes.psc}>TIMELINE</Box></Grid>
                                            <Grid item><Box className={classes.psc}>ESTIMATOR</Box></Grid>
                                        </Grid>
                                        <Grid item style={{ margin: '9px 0px 0px 0px' }}>
                                            <CardMedia image="/pte.png" component="img" />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.calculator} elevation={3} style={{ opacity: 0.5 }}>
                                <Grid container direction="row" wrap="nowrap" style={{ width: '240px', padding: '4px 0px 4px 0px' }}>
                                    <Grid item container direction="column" alignItems="flex-start" >
                                        <Grid item>
                                            <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
                                        </Grid>
                                        <Grid item><Box className={classes.psc}>CALCULATE</Box></Grid>
                                        <Grid item><Box className={classes.psc}>FEATURE</Box></Grid>
                                        <Grid item><Box className={classes.psc}>ROI</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ margin: '9px 0px 0px 0px' }}>
                                        <CardMedia image="/in_progress.png" component="img" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.calculator} elevation={3} style={{ opacity: 0.5 }}>
                                <Grid container direction="row" wrap="nowrap" style={{ width: '240px', padding: '4px 0px 4px 0px' }}>
                                    <Grid item container direction="column" alignItems="flex-start" >
                                        <Grid item>
                                            <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
                                        </Grid>
                                        <Grid item><Box className={classes.psc}>TEAM</Box></Grid>
                                        <Grid item><Box className={classes.psc}>BURN</Box></Grid>
                                        <Grid item><Box className={classes.psc}>RATE</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ margin: '9px 0px 0px 0px' }}>
                                        <CardMedia image="/in_progress.png" component="img" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.calculator} elevation={3} style={{ opacity: 0.5 }}>
                                <Grid container direction="row" wrap="nowrap" style={{ width: '240px', padding: '4px 0px 4px 0px' }}>
                                    <Grid item container direction="column" alignItems="flex-start" >
                                        <Grid item>
                                            <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
                                        </Grid>
                                        <Grid item><Box className={classes.psc}>LOAN TO</Box></Grid>
                                        <Grid item><Box className={classes.psc}>VALUE</Box></Grid>
                                        <Grid item><Box className={classes.psc}>CALCULATOR</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ margin: '9px 0px 0px 0px' }}>
                                        <CardMedia image="/in_progress.png" component="img" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: 48, marginBottom: 16, width: '100%' }}>
                        <CardMedia style={{ height: 1 }} image="/delimeter_horizontal.png" component="img" />
                    </Grid>
                    <Grid item container spacing={1} className={classes.bottom} direction="row" justify="flex-start" alignItems="center">
                        <Grid item><Box className={classes.footerText}>© 2020 teamofteams.io</Box></Grid>
                        <Grid item><a href="https://t.me/teamofteams" target="_blank"><CardMedia image="/footer-telegram-home.png" style={{ width: 30, height: 30 }} component="img" /></a></Grid>
                    </Grid>
                    <Grid item style={{ paddingLeft: 4 }}><Box>Los Angeles, California</Box></Grid>
                </Grid>
                <Grid item container direction="column" justify="center" alignItems="center" xs={12} sm={12} md={12} lg={4} className={classes.rightColumn}>
                    <Grid item><CardMedia image="/logo.png" component="img" /></Grid>
                    <Grid item style={{ marginTop: 8 }}><Box className={classes.slogan} style={{  }}>new era of decentralized collaboration</Box></Grid>
                    { homeFormState.quote !== '' &&
                        <Paper className={classes.dailyMotivationArea}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                <Grid item><Paper className={classes.booster} elevation={0} style={{ margin: 0 }}>Daily Motivation</Paper></Grid>
                                <Grid item><Box className={classes.dailyMotivationText} style={{ paddingBottom: homeFormState.author !== '' ? 0 : 8 }}>“{homeFormState.quote}”</Box></Grid>
                                { homeFormState.author !== '' && <Grid item style={{alignSelf: 'flex-end'}}><Box className={classes.dailyMotivationText} style={{ paddingTop: 0, paddingRight: 24 }}>&mdash; {homeFormState.author}</Box></Grid> }
                            </Grid>
                        </Paper>
                    }
                </Grid>
            </Grid>
        </Container> 
    );
}

export default Home;