/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "invites-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "psc-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "pte-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "waitlist-prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "quotes124500-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:cec7b083-29c6-4f85-b11b-32cf0fe73de5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OcHrOYmyr",
    "aws_user_pools_web_client_id": "77h1h48r96uicat2ju7ct1bmur",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "totapi",
            "endpoint": "https://w1y4f5u5ok.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
