import React from 'react';
import './App.css';

import { Switch, Route } from 'react-router-dom';
import Calculator from './Calculator';
import Estimator from './Estimator';
import Login from './Login';
import Home from './Home';
import HomeTwo from './HomeTwo';
import Initiation from './Initiation';
import DisclaimerCalculator from './DisclaimerCalculator';
import DisclaimerEstimator from './DisclaimerEstimator';
import Results from './Results';
import Timeline from './Timeline';
import { PreLoader } from './components';

function App() {
  return (
    <>
      <PreLoader />
      <Switch>
        <Route exact path="/results">
          <Results />
        </Route>
        <Route exact path="/timeline">
          <Timeline />
        </Route>
        <Route exact path="/calculator">
          <Calculator />
        </Route>
        <Route exact path="/estimator">
          <Estimator />
        </Route>
        <Route exact path="/disclaimer-calculator">
          <DisclaimerCalculator />
        </Route>
        <Route exact path="/disclaimer-estimator">
          <DisclaimerEstimator />
        </Route>
        <Route exact path="/initiate">
          <Initiation />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <HomeTwo />
        </Route>
      </Switch>
    </>
  );
}

export default App;