const initialState = { 
    email: '',
    joined: false
}

export function waitlist(state = initialState, action) {
    switch (action.type) {
        case 'JOINED_WAITLIST':
            return { joined: true }
        case 'WAITLIST_ERROR':
            return { joined: false }
        default:
            return state;
    }
}