import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { submitTimelineEstimate } from './actions';
import { Header, Footer } from './components';

import Container from '@material-ui/core/Container';
import { Grid, Paper, Box, RadioGroup, Radio, TextField, Switch, Button, FormControl, FormControlLabel } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    middle: {
        backgroundColor: '#F0F0F0',
        //minHeight: '92vh',
        //height: 750,
        //paddingTop: 24,
        paddingBottom: 24,
        margin: 0,
        width: '100%'
    },
    headers: {
        width: 320,
        backgroundImage: 'url("/pte_background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        paddingTop: 24,
        marginRight: -16
    },
    text: {
        color: '#000',
        fontFamily: 'Arial',
        textAlign: 'left',
        fontSize: 14
    },
    psc: {
        fontSize: 20,
        fontFamily: 'Muna'
    },
    disclaimerText: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 'bold'
    },
    button: {
        backgroundColor: '#B3DFE5',
        '&:hover': {
            backgroundColor: '#D9B800',
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    error: {
        backgroundColor: '#B74956',
        padding: '8px 16px',
        width: 275,
        textAlign: 'center'
    },
    input: {
        backgroundColor: '#C0F4FD',
        color: '#000000',
        borderRadius: 6,
        width: '275px',
        '&:hover': {
            backgroundColor: '#C0F4FD',
        },
        '&.Mui-focused': {
            backgroundColor: '#C0F4FD',
        }
    },
}));

const BlueRadio = withStyles({
    root: {
      '&$checked': {
        backgroundColor: '#C0F4FD',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

function Estimator() {
    const[estimatorFormState, setEstimatorFormState] = useState({
        numberOfTeams: 1,
        numberOfDevelopers: '',
        techLeadDevelops: true,
        developmentEffortDays: ''
    });

    function setInput(key, value) {
        if (key === 'numberOfDevelopers' && ((value < 2 || value > 6) && value !== '')) {
            return;
        }
        if (key === 'developmentEffortDays' && ((value < 1 || value > 600) && value !== '')) {
            return;
        }
        setEstimatorFormState({ ...estimatorFormState, [key]: value });
    }

    function validateInput() {
        if (estimatorFormState.numberOfDevelopers === '' || estimatorFormState.developmentEffortDays === '') {
            setEstimateError("Please enter all the parameters!");
            return false;
        }
        return (validateMinimumEffort());
    }

    function validateMinimumEffort() {
        if (estimatorFormState.numberOfTeams === 1) {
            if (estimatorFormState.developmentEffortDays / estimatorFormState.numberOfDevelopers < 6) {
                setEstimateError("Minimum effort for such team is " + estimatorFormState.numberOfDevelopers * 6 + " m/d");
                return false;
            } else {
                return true;
            }
        } else if (estimatorFormState.numberOfTeams === 2) {
            if (estimatorFormState.developmentEffortDays / estimatorFormState.numberOfDevelopers < 11) {
                setEstimateError("Minimum effort for such team is " + estimatorFormState.numberOfDevelopers * 11 + " m/d");
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    const dispatch = useDispatch();
    const timelineResult = useSelector(state => state.pte.timelineResult);
    const history = useHistory();

    const[estimateError, setEstimateError] = useState();

    function handleSubmitEstimate(e) {
        if (validateInput()) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6Le5L8oZAAAAABr0n8KZrmF9E0Ch6b1nJwvCT3vZ', {action: 'timeline'}).then(function(token) {
                    dispatch(submitTimelineEstimate(estimatorFormState, token));
                });
            });
        }
    }

    useEffect(() => {
        if (timelineResult && timelineResult !== '') {
            history.push("/timeline");
        }
    }, [timelineResult]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const classes = useStyles();

    return (
        <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 32 }}>
            <Header />
            <Grid container spacing={2} direction="column" justify="flex-start" alignItems="center" className={classes.middle}>
                <Grid container item direction="column" justify="flex-start" alignItems="center" className={classes.headers}>
                    <Grid item style={{ paddingBottom: 0, paddingTop: 24 }}><Box className={classes.psc}>PRODUCT TIMELINE</Box></Grid>
                    <Grid item style={{ paddingTop: 0 }}><Box className={classes.psc}>ESTIMATOR</Box></Grid>
                    <Grid item><Box style={{ fontSize: 12, fontFamily: 'Arial', marginTop: 24, marginBottom: 8, textAlign: 'center', width: 280 }}>Estimate your Implementation Timeline by Sprints in a few clicks</Box></Grid>
                </Grid>
                <Grid item style={{ width: 320 }}>
                    <Box className={classes.disclaimerText}>How many scrum development teams do you have?</Box>                    
                </Grid>
                <Grid item>
                    <FormControl component="fieldset">
                    <RadioGroup row name="numberOfTeams" value={estimatorFormState.numberOfTeams} onChange={(event) => setEstimatorFormState({ ...estimatorFormState, numberOfTeams: parseInt(event.target.value) }) }>
                        <FormControlLabel
                            value={1}
                            control={<BlueRadio />}
                            label="One Team"
                            labelPlacement="bottom"
                        />
                        <FormControlLabel
                            value={2}
                            control={<BlueRadio />}
                            label="Two Teams"
                            labelPlacement="bottom"
                        />
                    </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item style={{ width: 320 }}>
                    <Box className={classes.disclaimerText}>How many development engineers per scrum team, excluding Tech Lead? <span style={{fontSize: 12, fontFamily: 'Arial'}}>(min 2, max 6)</span></Box>
                </Grid>
                <Grid item>
                    <TextField required id="numberOfDevelopers" name="numberOfDevelopers" variant="filled" placeholder="Enter Number of Developers" margin="dense"
                        onChange={event => setInput('numberOfDevelopers', event.target.value)} value={estimatorFormState.numberOfDevelopers}
                        inputProps={{ min: 2, max: 6, style: { textAlign: 'left', fontSize: 18, padding: '10px 10px 10px' }}}
                        InputProps={{
                            classes: {
                                root: classes.input 
                            },
                            disableUnderline: true,
                            type: 'number'
                        }}
                    />
                </Grid>
                <Grid item style={{ width: 320 }}>
                    <Box className={classes.disclaimerText}>Does Tech Lead produce code?</Box>                    
                </Grid>
                <Grid item container spacing={2} direction="row" justify="center" alignItems="center" style={{ margin: 0, width: '100%' }}>
                    <Grid item>No</Grid>
                    <Grid item>
                        <Switch
                            checked={estimatorFormState.techLeadDevelops}
                            onChange={(event) => setEstimatorFormState({ ...estimatorFormState, techLeadDevelops: event.target.checked }) }
                            name="techLeadDevelops"
                            id="techLeadDevelops"
                        />
                    </Grid>
                    <Grid item>Yes</Grid>
                </Grid>
                <Grid item style={{ width: 320 }}>
                    <Box className={classes.disclaimerText}>How many development man-days of effort estimated in total for all teams? <span style={{fontSize: 12, fontFamily: 'Arial'}}>(min 12, max 600)</span></Box>                    
                </Grid>
                <Grid item>
                    <TextField required id="developmentEffortDays" name="developmentEffortDays" variant="filled" placeholder="Enter Effort in Man-Days" margin="dense"
                        onChange={event => setInput('developmentEffortDays', event.target.value)} value={estimatorFormState.developmentEffortDays}
                        inputProps={{ min: 12, max: 600, style: { textAlign: 'left', fontSize: 18, padding: '10px 10px 10px' }}}
                        InputProps={{
                            classes: {
                                root: classes.input 
                            },
                            disableUnderline: true,
                            type: 'number'
                        }}
                    />
                </Grid>
                { estimateError &&
                    <Grid item>
                        <Paper className={classes.error} elevation={3}><Box style={{ color: 'white', fontSize: 13 }}>{estimateError}</Box></Paper>
                    </Grid>
                }
                <Grid item>
                    <Button variant="contained" className={classes.button} onClick={handleSubmitEstimate}>Submit for Timeline</Button>
                </Grid>
            </Grid>
            <Footer />
        </Container>
    );
}

export default Estimator;