import React, { useState, useEffect } from 'react';

import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, IconButton, Container, CardMedia, Box, Paper, InputAdornment } from '@material-ui/core';
import SendSharpIcon from '@material-ui/icons/SendSharp';

import { login } from './actions';
import { Footer } from './components';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    text: {
        color: '#fff',
        fontFamily: 'Arial',
        textAlign: 'center'
    },
    input: {
        backgroundColor: '#C0F4FD',
        color: '#000000',
        borderRadius: 6,
        width: '275px',
        '&:hover': {
            backgroundColor: '#C0F4FD',
        },
        '&.Mui-focused': {
            backgroundColor: '#C0F4FD',
        }
    },
    calculator: {
        backgroundColor: '#F0F0F0',
        borderRadius: 11
    },
    booster: {
        backgroundColor: '#D9B800',
        color: '#fff',
        margin: '4px 0px 8px 8px',
        padding: '4px 12px 4px 12px',
        fontFamily: 'Helvetica Neue',
        borderRadius: 5
    },
    psc: {
        color: '#4A4A4A',
        margin: '0px 0px 0px 8px',
        fontSize: 16,
        fontFamily: 'Muna'
    },
    error: {
        backgroundColor: '#B74956',
        padding: '8px 16px',
        width: 275,
        textAlign: 'center'
    }
}));

function Login() {
    const[loginFormState, setLoginFormState] = useState({
        inviteId: '',
        pin: '',
        displayPin: false,
        displayLoginButton: false
    });

    function setInput(key, value) {
        setLoginFormState({ ...loginFormState, [key]: value });
    }

    useEffect(() => {
        // Validate inviteId format and show PIN input field if it matches
        if (validateInput(loginFormState.inviteId, 6)) {
            setLoginFormState({ ...loginFormState, displayPin: true });
        } else {
            setLoginFormState({ ...loginFormState, displayPin: false });
        }
    }, [loginFormState.inviteId]);

    useEffect(() => {
        // Validate pin format and show login button if it matches
        if (validateInput(loginFormState.pin, 4)) {
            setLoginFormState({ ...loginFormState, displayLoginButton: true });
        } else {
            setLoginFormState({ ...loginFormState, displayLoginButton: false });
        }
    }, [loginFormState.pin]);

    function validateInput(field, requiredLength) {
        if (field !== '' && field.length >= requiredLength) {
            return true;
        } else {
            return false;
        }
    }

    const firstName = useSelector(state => state.authentication.firstName);
    const loginError = useSelector(state => state.authentication.error);
    const dispatch = useDispatch();
    const history = useHistory();

    function handleInviteId(e) {
        if (validateInput(loginFormState.inviteId, 6) && validateInput(loginFormState.pin, 4) && /^[0-9\b]+$/.test(loginFormState.inviteId)) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6Le5L8oZAAAAABr0n8KZrmF9E0Ch6b1nJwvCT3vZ', {action: 'login'}).then(function(token) {
                    dispatch(login(loginFormState.inviteId, loginFormState.pin, token));
                });
            });
        }
    }

    useEffect(() => {
        if (firstName && firstName !== '') {
            history.push("/initiate");
        }
    }, [firstName]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const classes = useStyles();

    return (
        <Container component="main" maxWidth={false} className="App">
            <form className={classes.form} noValidate>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
                <Grid item>
                    <CardMedia image="/logo.png" component="img" />
                    <Box style={{ fontFamily: 'Arial', fontSize: 26, color: '#fff', textAlign: 'center' }}>new era of decentralized collaboration</Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <Box className={classes.text}>
                    Today, when people all over the world come together in communities and strive to stay together, the most important factors of business value will be the ability to partnership, empathy and justice.
                    <br />
                    <br />
                    Team of Teams digital platform, developed by Y-Z generations from eeutech.com, provides new rules of engagement ultimately driven by values.
                    </Box>
                </Grid>
                <Grid item>
                    <TextField required id="inviteId" name="inviteId" variant="filled" placeholder="Enter Your Invite ID" margin="dense"
                        onChange={event => setInput('inviteId', event.target.value)} value={loginFormState.inviteId}
                        inputProps={{style: { textAlign: 'left', fontSize: 20, padding: '10px 10px 10px' }, maxLength: 16}}
                        InputProps={{
                            classes: {
                                root: classes.input 
                            },
                            disableUnderline: true
                        }}
                    />
                </Grid>
                { loginFormState.displayPin &&
                <Grid item>
                    <TextField required id="pin" name="pin" variant="filled" placeholder="Enter Your PIN" margin="dense" type="password"
                        onChange={event => setInput('pin', event.target.value)} value={loginFormState.pin}
                        inputProps={{style: { textAlign: 'left', fontSize: 20, padding: '10px 10px 10px' }, maxLength: 16}}
                        InputProps={{
                            classes: {
                                root: classes.input 
                            },
                            disableUnderline: true,
                            endAdornment: loginFormState.displayLoginButton && (
                                <InputAdornment position='end'>
                                    <IconButton onClick={handleInviteId}><SendSharpIcon style={{ fill: 'black'}} /></IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                }
                { loginError && 
                    <Grid item>
                        <Paper className={classes.error} elevation={3}><Box style={{color: 'white'}}>{loginError}</Box></Paper>
                    </Grid>
                }
                <Grid item container spacing={2} direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Link to="/disclaimer-calculator" style={{ textDecoration: 'none' }}>
                            <Paper className={classes.calculator} elevation={3}>
                                <Grid container direction="row" wrap="nowrap" style={{ width: '230px', padding: '4px 0px 4px 0px' }}>
                                    <Grid item container direction="column" alignItems="flex-start" >
                                        <Grid item>
                                            <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
                                        </Grid>
                                        <Grid item><Box className={classes.psc}>PRODUCT</Box></Grid>
                                        <Grid item><Box className={classes.psc}>SUCCESS</Box></Grid>
                                        <Grid item><Box className={classes.psc}>CALCULATOR</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ margin: '0px -8px 0px 0px' }}>
                                        <CardMedia image="/psc.png" component="img" /> 
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/disclaimer-estimator" style={{ textDecoration: 'none' }}>
                            <Paper className={classes.calculator} elevation={3}>
                                <Grid container direction="row" wrap="nowrap" style={{ width: '230px', padding: '4px 0px 4px 0px' }}>
                                    <Grid item container direction="column" alignItems="flex-start" >
                                        <Grid item>
                                            <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
                                        </Grid>
                                        <Grid item><Box className={classes.psc}>PRODUCT</Box></Grid>
                                        <Grid item><Box className={classes.psc}>TIMELINE</Box></Grid>
                                        <Grid item><Box className={classes.psc}>ESTIMATOR</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ margin: '9px 0px 0px 0px' }}>
                                        <CardMedia image="/pte.png" component="img" />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
                <Footer />
            </Grid>
            </form>
        </Container>
    );
}

export default Login;