const initialState = { 
    timelineResult: '',
    timelineEmailSent: false
}

export function pte(state = initialState, action) {
    switch (action.type) {
        case 'RESET_TIMELINE':
            return { timelineResult: '', timelineEmailSent: false }
        case 'SUBMIT_ESTIMATE_FOR_TIMELINE':
            return { timelineResult: action.timelineResult };
        case 'SUBMIT_TIMELINE_PRODUCT_DETAILS':
            return { ...state, timelineEmailSent: true };
        case 'TIMELINE_ERROR':
            return { error: action.error };
        default:
            return state;
    }
}