import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import { Grid, Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Header, Footer } from './components';

const useStyles = makeStyles((theme) => ({
    middle: {
        backgroundColor: '#F0F0F0',
        //minHeight: '92vh',
        //height: 650,
        //paddingTop: 24,
        paddingBottom: 24,
        margin: 0,
        width: '100%'
    },
    headers: {
        width: 320,
        backgroundImage: 'url("/spell_black_background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        paddingTop: 24,
        marginRight: -16
    },
    text: {
        color: '#000',
        fontFamily: 'Roboto',
        textAlign: 'left',
        fontSize: 12
    },
    psc: {
        fontSize: 20,
        fontFamily: 'Muna'
    },
    disclaimerText: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        marginTop: 24,
        marginBottom: 18
    },
    button: {
        backgroundColor: '#B3DFE5',
        '&:hover': {
            backgroundColor: '#D9B800',
        }
    }
}));

function DisclaimerCalculator() {

    const dispatch = useDispatch();

    function handleAcceptDisclaimer() {
        dispatch({ type: 'RESET_QUESTIONNAIRE' });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const classes = useStyles();

    return (
        <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 32 }}>
            <Header />
            <Grid container spacing={2} direction="column" justify="flex-start" alignItems="center" className={classes.middle}>
                <Grid container item direction="column" justify="flex-start" alignItems="center" className={classes.headers}>
                    <Grid item style={{ paddingBottom: 0, paddingTop: 24}}><Box className={classes.psc}>PRODUCT SUCCESS</Box></Grid>
                    <Grid item style={{ paddingTop: 0 }}><Box className={classes.psc}>CALCULATOR</Box></Grid>
                    <Grid item><Box className={classes.disclaimerText}>DISCLAIMER</Box></Grid>
                </Grid>
                <Grid item style={{ width: 320 }}>
                    <Box className={classes.text}>
                        1. "TT Accelerator" on the teamofteams.io platform is free to use subject specific accelerator.
                        <br />
                        <br />
                        2. The currently selected "TT Accelerator" named as "Product Success Calculator" (PSC).
                        <br />
                        <br />
                        3. The PSC is designed primarily for early stage startups and young founders who don’t have live product and operations yet.
                        <br />
                        <br />
                        4. The data you will enter during the assessment will remain secure in cloud storage located in USA geo zone.
                        <br />
                        <br />
                        5. The current version of the PSC is evaluating your product idea by certain selected attributes and algorithm developed by the platform implementation team. At the end of the assessment, the PCS provides successful rate for the potential product launch and related recommendations.
                        <br />
                        <br />
                        6. Advanced, more objective qualifications are available for invited TT platform members and will be assessed under PCS v2.0.
                        <br />
                        <br />
                        7. The PSC pages are using invisible Google reCAPTCHA. The reCAPTCHA collects personal information from users to make this determination of whether they're human and not a bot. By proceeding using the PSC you are agreeing to the <a href="https://policies.google.com/privacy?hl=en" target="_blank">Google Privacy Policy</a> & <a href="https://policies.google.com/terms?hl=en" target="_blank">Terms of Use</a>.
                        <br />
                        <br />                     
                        8. At the end of the assessment we propose to save your results and badge reward by entering your email and some specific details about your product per user story template. The email address may be used by our team for future TT product announcements and your product description may be used for invites to join the TT platform members area.
                        <br />
                        <br />   
                        9. EU users: by proceeding to use the PSC, you confirm that you are above 18 years of age (GDPR requirement).
                        <br />
                        <br /> 
                        10. EU users: our company doesn't collect any data besides that you enter during the assessment. Additional technical data may be collected by cloud technology provider.
                        <br />
                        <br /> 
                        11. To contact us, please email to tt@eeutech.com
                        <br />
                        <br /> 
                    </Box>
                </Grid>
                <Grid item container spacing={2} direction="row" justify="center" alignItems="center" style={{ paddingTop: 8, margin: 0, width: '100%' }}>
                    <Grid item><Link to="/" style={{ textDecoration: 'none' }}><Button variant="contained" className={classes.button}>Back</Button></Link></Grid>
                    <Grid item><Link to="/calculator" style={{ textDecoration: 'none' }}><Button variant="contained" className={classes.button} onClick={handleAcceptDisclaimer}>Accept</Button></Link></Grid>
                </Grid>
            </Grid>
            <Footer />
        </Container>
    );
}

export default DisclaimerCalculator;
