export const questionnaire = 
[
    {
        question: "Select primary geolocation of your customers?",
        answerOptions: ["Worldwide", "USA & Canada", "Europe", "Asia", "Australia", "Africa", "Latin America", "CIS"]
    },
    {
        question: "How many users will want to use your product?",
        answerOptions: ["Every person in the area", "Most of the people (80+%)", "Half of the people (50+%)", "Some people (30+%)", "Specific people (10+%)"]
    },
    {
        question: "Which segment do your customers (people / businesses) belong to?",
        answerOptions: ["Ultra-wealthy (top 0.1%) / Extraordinary", "Wealthy (top 1%) / Wealth Mature Companies", "Middle-Upper Class (top 10%) / Enterprise Companies", "Regular folks (General Public) / Medium Businesses or Charity / Donation", "Lean / Small Businesses"]
    },
    {
        question: "Will your product require adoption (people will require some time to get used using this product)?",
        answerOptions: ["No, there are similar products used today", "Some guidance to users will be required", "Yes, it is new way of doing things (no similar products)"]
    },
    {
        question: "How many users did you speak to who are ready to use your product?",
        answerOptions: ["Nobody yet or less than 10 people", "10 - 30", "More than 30"]
    },
    {
        question: "How frequently do people experience the need for your product?",
        answerOptions: ["Several times a day", "Several times a week", "Several times a month", "Several times a year"]
    },
    {
        question: "Can you do any hands-on work for the implementation of your product or its major components?",
        answerOptions: ["Yes", "No"]
    },
    {
        question: "Who is in your team?",
        answerOptions: ["Myself or a co-founder(s)", "I have technical team for implementing my product", "I have full stack team to push product on a market"]
    },
    {
        question: "Do you need external funding (at least partially) to build and release your product?",
        answerOptions: ["Yes", "No"]
    },
    {
        question: "What stage are you on?",
        answerOptions: ["Just an idea", "Visual Concept", "Prototype"]
    },
    {
        question: "What is your available commitment to implement the product?",
        answerOptions: ["Part-time during the week", "Full-time", "Week-ends only"]
    },
    {
        question: "What market segment does your product belong to?",
        answerOptions: ["Innovation or emerging market", "New product on existing market", "Copying existing product & improving its features or distinguished by business model"]
    },
    {
        question: "What is the competition rate on the existing market?",
        answerOptions: ["Very little (less than 2 major players)", "Moderate (2-3 major players)", "Very high (more than 3 major players)"]
    },
    {
        question: "What 'secret sauce' do you have to make this startup successful?",
        answerOptions: ["Hands-on technical implementation", "Product development expertise", "Marketing & sales strategy", "Available technical team", "Unique industry expertise", "Rich professional start-up network", "Firsthand exposure to Venture Capitalists"],
        multiSelect: 'true'
    }
]