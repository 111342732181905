import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Backdrop } from '@material-ui/core';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    },
}));

export function PreLoader(props) {
    const loader = useSelector(state => state.preloader.loading);

    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={loader}>
            <CardMedia style={{ width: 150, height: 150 }} image="/preloader.gif" component="img" />
        </Backdrop>
    )
}