import React, { useState, useEffect } from 'react';

import Carousel from 'react-material-ui-carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';
import './HomeTwo.css';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Grid, CardMedia, Box, Fab, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Paper, useMediaQuery } from '@material-ui/core';

import { joinWaitlist } from './actions';

const useStyles = makeStyles((theme) => ({
    slogan: {
        fontWeight: "bold",
        fontSize: 20,
        fontFamily: "Palatino"
    },
    headerText: {
        color: "#FFC400",
        fontWeight: "bold",
        fontSize: 65,
        fontFamily: "Palatino",
        [theme.breakpoints.down('xs')]: {
            fontSize: 52
        }
    },
    listText: {
        fontSize: 34,
        fontFamily: "Arial",
        paddingLeft: 16,
        paddingTop: 8,
        [theme.breakpoints.down('xs')]: {
            fontSize: 27
        }
    },
    motto: {
        fontSize: 34,
        fontFamily: "Apple Symbols",
        backgroundColor: "#FFC400",
        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.down('xs')]: {
            fontSize: 27
        }
    },
    phoneText: {
        fontSize: 24,
        fontFamily: "Arial",
        textAlign: 'center'
    },
    carouselText: {
        fontSize: 22,
        fontFamily: "Arial",
        [theme.breakpoints.down('xs')]: {
            fontSize: 17
        }
    },
    featuresHeader: {
        fontSize: 34,
        fontFamily: "Arial",
        fontWeight: "bold",
        paddingLeft: 8
    },
    featuresText: {
        fontSize: 20,
        fontFamily: "Arial",
        color: "#709102"
    },
    personasHeader: {
        fontSize: 30,
        fontFamily: "Arial",
        fontWeight: "bold"
    },
    personasText: {
        fontSize: 25,
        fontFamily: "Arial",
        color: "#709102",
        [theme.breakpoints.down('xs')]: {
            fontSize: 24
        }
    },
    acceleratorsHeaderText: {
        fontSize: 30,
        fontFamily: "Arial"
    },
    calculator: {
        borderRadius: '18px 18px 0px 0px',
        border: 'solid 1px',
        width: 495,
        height: 356,
        [theme.breakpoints.down('xs')]: {
            width: 343,
            height: 247
        }
    },
    psc: {
        fontSize: 45,
        fontFamily: 'Arial',
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        }
    },
    acceleratorsDescText: {
        fontSize: 22,
        fontFamily: 'Arial',
        color: '#709102',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    },
    acceleratorsDescArea: {
        backgroundColor: '#F7FFE2',
        width: '100%',
        height: 98,
        textAlign: 'center',
        borderTop: 'solid 1px',
        [theme.breakpoints.down('xs')]: {
            height: 68
        }
    },
    productivityHeaderText: {
        fontSize: 120,
        fontFamily: 'Arial'
    },
    pointsCarousel: {
        height: 96,
        width: 800,
        [theme.breakpoints.down('xs')]: {
            width: 375
        }
    },
    footerText: {
        color: '#000000',
        fontFamily: 'Arial',
        fontSize: 14
    },
    thankYouArea: {
        borderRadius: 9,
        border: '1px dotted black',
        padding: 8,
        backgroundColor: '#CEFF00'
    },
    button: {
        backgroundColor: '#FFC400',
        '&:hover': {
            backgroundColor: '#CEFF00',
        },
        textTransform: 'none',
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
            width: 280
        }
    },
    input: {
        color: '#000000',
        borderRadius: 6
    },
    joinFloatingButton: {
        margin: 0,
        top: 'auto',
        right: -64,
        bottom: 'calc(50%)',
        left: 'auto',
        position: 'fixed',
        transform: 'rotate(-90deg)',
        backgroundColor: '#FFC400',
        '&:hover': {
            backgroundColor: '#CEFF00',
        },
        zIndex: 10,
        borderRadius: 0,
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            right: -88
        }
    },
    joinDialog: {
        width: 600,
        [theme.breakpoints.down('xs')]: {
            width: 300
        }
    },
    floatingTelegram: {
        margin: 0,
        top: 'auto',
        right: 42,
        bottom: 'calc(50% + 157px)',
        left: 'auto',
        position: 'fixed',
        minHeight: 0,
        width: 0,
        height: 0,
        zIndex: 10,
        [theme.breakpoints.down('xs')]: {
            right: 20
        }
    },
    floatingProductHunt: {
        margin: 0,
        top: 'auto',
        bottom: 42,
        left: 'auto',
        right: 142,
        position: 'fixed',        
        zIndex: 10,
        minHeight: 0,
        width: 0,
        height: 0,
        [theme.breakpoints.down('xs')]: {
            bottom: 'calc(50% - 221px)',
            right: 24,
            transform: 'rotate(-90deg)',
        }
    }
}));

function HomeTwo() {
    const [openJoinDialog, setOpenJoinDialog] = useState(false);
    const [homeFormState, setHomeFormState] = useState({
        email: ''
    });

    function setInput(key, value) {
        setHomeFormState({ ...homeFormState, [key]: value });
    }

    function handleClickOpen() {
        setOpenJoinDialog(true);
    }

    function handleClose() {
        setOpenJoinDialog(false);
    };

    const joinedWaitlist = useSelector(state => state.waitlist.joined);
    const dispatch = useDispatch();

    function handleJoinWaitlist(e) {
        if (validateEmail(homeFormState.email)) {
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6Le5L8oZAAAAABr0n8KZrmF9E0Ch6b1nJwvCT3vZ', {action: 'waitlist'}).then(function(token) {
                    dispatch(joinWaitlist(homeFormState.email, token))
                });
            });
        }
    }

    function validateEmail(email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    useEffect(() => {
        AOS.init({
            duration: 1000
        });

        document.body.style.backgroundImage = "unset";
        return () => {
            document.body.style.backgroundImage = 'url("/background.png")';
        }
    }, []);

    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Container component="main" maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Fab className={classes.joinFloatingButton} variant="extended" onClick={handleClickOpen}>JOIN THE INVITATION LIST</Fab>
            <Fab className={classes.floatingTelegram}><a href="https://t.me/teamofteams" target="_blank"><CardMedia image="/footer-telegram-home.png" style={{ width: 30, height: 30 }} component="img" /></a></Fab>
            <Fab className={classes.floatingProductHunt}><a href="https://www.producthunt.com/posts/team-of-teams-2?utm_source=badge-review&utm_medium=badge&utm_souce=badge-team-of-teams-2#discussion-body" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=311331&theme=light" alt="Team of Teams - ML driven young co-founders match and equities distribution | Product Hunt" style={{width: 250, height: 54}} width="250" height="54" /></a></Fab>
            <Dialog open={openJoinDialog} onClose={handleClose}>
                <DialogTitle>Subscribe</DialogTitle>
                <DialogContent className={classes.joinDialog}>
                    { joinedWaitlist ?
                        <Paper className={classes.thankYouArea}>
                            <Box style={{ textAlign: 'center' }}>Thank you for your interest. Stay tuned for further updates!</Box>
                        </Paper>
                    :
                        <TextField required fullWidth id="email" name="email" variant="outlined" placeholder="Enter your email"
                            onChange={event => setInput('email', event.target.value)} value={homeFormState.email}
                            inputProps={{ style: { textAlign: 'center', fontSize: 15, padding: '11px' }, maxLength: 64 }}
                            InputProps={{
                                classes: {
                                    root: classes.input 
                                },
                                disableUnderline: true
                            }}
                        />
                    }
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    { !joinedWaitlist  && <Button variant="contained" className={classes.button} onClick={handleJoinWaitlist}>Get your invitation soon!</Button> }
                </DialogActions>
            </Dialog>
            <Grid container direction="column" justify="center" alignItems="center" style={{ paddingLeft: 8 }}>
                <Grid item container direction="column" justify="center" alignItems="center">
                    <Grid item style={{ marginTop: 24 }}><CardMedia image="/main_logo.png" component="img" /></Grid>
                    <Grid item style={{ marginTop: 8 }}><Box className={classes.slogan}>NEW RULES OF ENGAGEMENT</Box></Grid>
                    <Grid item style={{ marginTop: 16 }}><CardMedia style={{ width: mobile ? 350 : 700, height: 1 }} image="/delimeter_horizontal.png" component="img" /></Grid>
                </Grid>
                <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item container direction="column" justify="center" alignItems="center" style={{ width: 800, margin: 32 }}>
                        <Grid item>
                            <Box className={classes.headerText}>"LEMONS" TO EAT:</Box>
                            <ul>
                                <li><Box className={classes.listText}>Investors follow more co-founder setups?</Box></li>
                                <li><Box className={classes.listText}>Looking for a right cultural fit within your team?</Box></li>
                                <li><Box className={classes.listText}>Want to continuously manage company equity distribution and build trust?</Box></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item style={{ width: 400, margin: 32 }}><CardMedia image="/lemons.png" component="img" /></Grid>
                </Grid>
                <Grid item container direction="column" justify="flex-start" alignItems="center">
                    <Grid item><Box className={classes.motto}>LET’S SQUEEZE IT AS A TEAM!</Box></Grid>
                </Grid>
                <Grid item container direction="row" justify="center" alignItems="center" style={{ marginTop: 32 }}>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                    <Grid item style={{ marginLeft: 16, marginRight: 16 }}><CardMedia image="/arrow_delimeter_light.png" component="img" /></Grid>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" data-aos="slide-up">
                <Grid item style={{ margin: 32 }}><Box className={classes.headerText} style={{ textAlign: mobile ? 'left' : 'center' }}>HOW WE HELP YOU TO EAT “LEMONS”</Box></Grid>
                <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item container direction="column" justify="flex-start" alignItems="center" style={{ width: 400, margin: 32 }}>
                        <Grid item><CardMedia image="/phone_lemon.png" component="img" /></Grid>
                        <Grid item style={{ marginTop: 24 }}><Box className={classes.phoneText}>Founders & Partners ML Driven Matchmark</Box></Grid>
                    </Grid>
                    <Grid item container direction="column" justify="flex-start" alignItems="center" style={{ width: 400, margin: 32 }}>
                        <Grid item><CardMedia image="/phone_lemon.png" component="img" /></Grid>
                        <Grid item style={{ marginTop: 24 }}><Box className={classes.phoneText}>Continuous Feedback on an Engagement & Culture Environment</Box></Grid>
                    </Grid>
                    <Grid item container direction="column" justify="flex-start" alignItems="center" style={{ width: 400, margin: 32 }}>
                        <Grid item><CardMedia image="/phone_lemon.png" component="img" /></Grid>
                        <Grid item style={{ marginTop: 24 }}><Box className={classes.phoneText}>Open Trust ID designated to Startup Projects and Teams</Box></Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                    <Grid item style={{ marginLeft: 16, marginRight: 16 }}><CardMedia image="/arrow_delimeter_light.png" component="img" /></Grid>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" data-aos="slide-up" style={{ marginTop: 16 }}>
                <Grid item style={{ width: mobile ? 345 : 900, margin: 16 }}><Box className={classes.headerText} style={{ fontSize: mobile ? 44 : 65 }}>EATING “LEMONS” IS HEALTHY. ESPECIALLY, IN COMBINATION WITH THE FOLLOWING FEATURES:</Box></Grid>
                <Grid item container direction="row" justify="center" alignItems="center" style={{ marginTop: 32 }}>
                    <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} lg={6} style={{ marginBottom: 32, alignContent: 'flex-end', paddingRight: mobile ? 0 : 24 }}>
                        <Grid item><ul><li><Box className={classes.featuresHeader}>VALUES MANAGEMENT</Box></li></ul></Grid>
                        <Grid item style={{ paddingLeft: 24, paddingRight: 16, width: mobile ? 375 : 500 }}><Box className={classes.featuresText}>Core entity that drives your engagements and matches on the platform.</Box></Grid>
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} lg={6} style={{ marginBottom: 32 }}>
                        <Grid item><ul><li><Box className={classes.featuresHeader}>EQUITY DISTRIBUTION</Box></li></ul></Grid>
                        <Grid item style={{ paddingLeft: 24, paddingRight: 16, width: mobile ? 375 : 500 }}><Box className={classes.featuresText}>Smart mechanics to track shares distribution based on contributions and business goals.</Box></Grid>
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} lg={6} style={{ marginBottom: 32, alignContent: 'flex-end', paddingRight: mobile ? 0 : 24 }}>
                        <Grid item><ul><li><Box className={classes.featuresHeader}>ONLINE ACCELERATORS<br />IN A POCKET</Box></li></ul></Grid>
                        <Grid item style={{ paddingLeft: 24, paddingRight: 16, width: mobile ? 375 : 500 }}><Box className={classes.featuresText}>Wide range of free online tools for Founders & Product Managers to accelerate variety of metrics needed for a startup or a project.</Box></Grid>
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="flex-start" xs={12} lg={6} style={{ marginBottom: 32, paddingBottom: 28 }}>
                        <Grid item><ul><li><Box className={classes.featuresHeader}>TRUSTED PROFESSIONAL<br />NETWORK</Box></li></ul></Grid>
                        <Grid item style={{ paddingLeft: 24, paddingRight: 16, width: mobile ? 375 : 500 }}><Box className={classes.featuresText}>Already filtered and verified knowledge sharing among trusted network of professionals.</Box></Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                    <Grid item style={{ marginLeft: 16, marginRight: 16 }}><CardMedia image="/arrow_delimeter_light.png" component="img" /></Grid>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" data-aos="slide-up" style={{ marginTop: 16, marginBottom: 32 }}>
                <Grid item style={{ margin: 16 }}><Box className={classes.headerText} style={{ textAlign: mobile ? 'left' : 'center' }}>LET’S GET IT FLOW IN 7 POINTS</Box></Grid>
                <Grid item container direction="column" justify="center" alignItems="center" style={{ marginTop: 128 }}>
                    <Carousel className={classes.pointsCarousel} autoPlay={true} indicators={false} navButtonsAlwaysVisible={mobile ? false : true} navButtonsAlwaysInvisible={mobile ? true : false} animation="fade" NextIcon={<CardMedia image="/arrow_right.png" component="img" />} PrevIcon={<CardMedia image="/arrow_left.png" component="img" />}
                        navButtonsProps={{
                            style: {
                                backgroundColor: "white",
                                position: "initial"
                            }
                        }}>
                        <Grid item style={{ paddingLeft: mobile ? 32 : 128, paddingRight: mobile ? 32 : 128 }}><Box className={classes.carouselText}><br /><b>01.</b> You want to kick-off product idea as a startup or you need a co-founder.</Box></Grid>
                        <Grid item style={{ paddingLeft: mobile ? 32 : 128, paddingRight: mobile ? 32 : 128 }}><Box className={classes.carouselText}><b>02.</b> You register at teamofteams.io and fill in two fields: location & primary role. Also select a core, the most important value to start with.</Box></Grid>
                        <Grid item style={{ paddingLeft: mobile ? 32 : 128, paddingRight: mobile ? 32 : 128 }}><Box className={classes.carouselText}><b>03.</b> ML algorithm helps you find the right partners and co-founders for building your product by providing success engagement rate.</Box></Grid>
                        <Grid item style={{ paddingLeft: mobile ? 32 : 128, paddingRight: mobile ? 32 : 128 }}><Box className={classes.carouselText}><b>04.</b> Work with professionals, who are sharing the same values and principles. Leave digital footprint for a wide variety of metrics in professional community.</Box></Grid>
                        <Grid item style={{ paddingLeft: mobile ? 32 : 128, paddingRight: mobile ? 32 : 128 }}><Box className={classes.carouselText}><br /><b>05.</b> Earn measurable reputation for Open Trust ID.</Box></Grid>
                        <Grid item style={{ paddingLeft: mobile ? 32 : 128, paddingRight: mobile ? 32 : 128 }}><Box className={classes.carouselText}><br /><b>06.</b> Earn tokens for completed product / project goals to keep up your shares.</Box></Grid>
                        <Grid item style={{ paddingLeft: mobile ? 32 : 128, paddingRight: mobile ? 32 : 128 }}><Box className={classes.carouselText}><b>07.</b> Release your products, enjoy working with your team, share your profile achievements and trust levels for future engagements.</Box></Grid>
                    </Carousel>
                </Grid>
                <Grid item container direction="row" justify="center" alignItems="center" style={{ marginTop: 128 }}>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                    <Grid item style={{ marginLeft: 16, marginRight: 16 }}><CardMedia image="/arrow_delimeter_light.png" component="img" /></Grid>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" data-aos="slide-up" style={{ marginTop: 16, marginBottom: 32 }}>
                <Grid item style={{ width: mobile ? 345 : 855, margin: 16 }}><Box className={classes.headerText} style={{ fontSize: mobile ? 44 : 65 }}>THESE GUYS HAVE ALREADY CRUSHED THROUGH “LEMONS” AND SEE WHAT HAPPENS</Box></Grid>
                <Grid item container direction="column" justify="center" alignItems="center" style={{ marginTop: 64 }}>
                    <Carousel autoPlay={false} indicators={true} navButtonsAlwaysVisible={false} navButtonsAlwaysInvisible={true} animation="fade"
                        indicatorIconButtonProps={{
                            style: { padding: '10px', color: '#FFC400' }
                        }}
                        activeIndicatorIconButtonProps={{
                            style: { color: '#CEFF00' }
                        }}
                        indicatorContainerProps={{
                            style: { marginTop: '24px' }
                        }}>
                        <Grid item container direction="column" justify="center" alignItems="center" >
                            <Grid item><CardMedia image="/zach_persona.png" component="img" /></Grid>
                            <Grid item style={{ marginTop: 16 }}><Box className={classes.personasHeader}>ZACH</Box></Grid>
                            <Grid item style={{ marginTop: 24, width: mobile ? 345 : 800 }}>
                                <Box className={classes.personasText}>
                                    Found co-founder for his startup based on Open Values & Trust ID.
                                    Having healthy relationship and feedback loops driven by personalized
                                    values model. Zach and his partner are happy and ready to expand the
                                    team based on the metrics and tools the platform provides.
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" justify="center" alignItems="center" style={{ paddingBottom: 8 }}>
                            <Grid item><CardMedia image="/elaina_persona.png" component="img" /></Grid>
                            <Grid item style={{ marginTop: 16 }}><Box className={classes.personasHeader}>ELAINA</Box></Grid>
                            <Grid item style={{ marginTop: 24, width: mobile ? 345 : 800 }}>
                                <Box className={classes.personasText}>
                                    Happy and successful in her 1st startup seed. Angel investors were
                                    trying to manage solo-founder risk and Elaina was able to build
                                    enough perspective and measurable relationship with her co-founder in
                                    order to assure investors in healthy and long term commitment from both.
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" justify="center" alignItems="center" style={{ paddingBottom: 8 }}>
                            <Grid item><CardMedia image="/phillip_persona.png" component="img" /></Grid>
                            <Grid item style={{ marginTop: 16 }}><Box className={classes.personasHeader}>PHILLIP</Box></Grid>
                            <Grid item style={{ marginTop: 24, width: mobile ? 345 : 800 }}>
                                <Box className={classes.personasText}>
                                    Building his business relationship based on values.
                                    Enjoying less conversations about org-charts, rather than conversations on how to create value driven products.
                                    Phillip's value creation network is expanding with meaningful connections, standalone high performing teams available for product development.
                                </Box>
                            </Grid>
                        </Grid>
                    </Carousel>
                </Grid>
                <Grid item container direction="row" justify="center" alignItems="center" style={{ marginTop: 64 }}>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                    <Grid item style={{ marginLeft: 16, marginRight: 16 }}><CardMedia image="/arrow_delimeter_light.png" component="img" /></Grid>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" data-aos="slide-up" style={{ marginTop: 32, marginBottom: 32 }}>
                <Grid item style={{ width: mobile ? 345 : 850, margin: 16 }}><Box className={classes.headerText}>WE ALSO ACCELERATE YOUR EXPERIENCE WITH SOME FRESH <span style={{ color: '#87B94D'}}>LIMES </span><CardMedia image="/lime.png" component="img" style={{ width: 68, height: 71, display: "inline", whiteSpace: "nowrap" }} /></Box></Grid>
                <Grid item style={{ width: mobile ? 345 : 850, margin: 16 }}><Box className={classes.acceleratorsHeaderText} style={{ fontSize: mobile ? 26 : 30 }}>teamofteams.io provides various industry related tools to accelerate processes and KPIs calculations in partnership models and product development</Box></Grid>
                <Grid item container direction="row" justify="center" alignItems="center" style={{ marginTop: 32 }}>
                    <Grid item style={{ padding: mobile ? 16 : 32 }}>
                        <Link to="/disclaimer-calculator" style={{ textDecoration: 'none' }}>
                            <Paper className={classes.calculator} elevation={3}>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item container direction="row" wrap="nowrap">
                                        <Grid item container direction="column" alignItems="flex-start" style={{ paddingTop: 32, paddingLeft: 32 }}>
                                            <Grid item><Box className={classes.psc}>PRODUCT</Box></Grid>
                                            <Grid item><Box className={classes.psc}>SUCCESS</Box></Grid>
                                            <Grid item><Box className={classes.psc}>CALCULATOR</Box></Grid>
                                        </Grid>
                                        <Grid item style={{ paddingTop: 32, paddingRight: 32 }}>
                                            <CardMedia image="/psc.png" component="img" />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.acceleratorsDescArea} style={{ marginTop: mobile ? 19 : 32, paddingLeft: mobile ? 27 : 32, paddingTop: mobile ? 12 : 16 }}>
                                        <Box className={classes.acceleratorsDescText}>Get your product idea success rate in a few clicks using TOT algorithm.</Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item style={{ padding: mobile ? 16 : 32 }}>
                        <Link to="/disclaimer-estimator" style={{ textDecoration: 'none' }}>
                            <Paper className={classes.calculator} elevation={3}>
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item container direction="row" wrap="nowrap">
                                        <Grid item container direction="column" alignItems="flex-start" style={{ paddingTop: 32, paddingLeft: 32 }}>
                                            <Grid item><Box className={classes.psc}>PRODUCT</Box></Grid>
                                            <Grid item><Box className={classes.psc}>TIMELINE</Box></Grid>
                                            <Grid item><Box className={classes.psc}>ESTIMATOR</Box></Grid>
                                        </Grid>
                                        <Grid item style={{ paddingTop: 32, paddingRight: 32 }}>
                                            <CardMedia image="/pte.png" component="img" />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.acceleratorsDescArea} style={{ marginTop: mobile ? 19 : 32, paddingLeft: mobile ? 21 : 32, paddingTop: mobile ? 12 : 16 }}>
                                        <Box className={classes.acceleratorsDescText}>Product implementation timeline estimate based on m/d estimates and other factors.</Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item style={{ padding: mobile ? 16 : 32 }}>
                        <Paper className={classes.calculator} elevation={3} style={{ opacity: 0.5 }}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Grid item container direction="row" wrap="nowrap">
                                    <Grid item container direction="column" alignItems="flex-start" style={{ paddingTop: 32, paddingLeft: 32 }}>
                                        <Grid item><Box className={classes.psc}>CALCULATE</Box></Grid>
                                        <Grid item><Box className={classes.psc}>FEATURE</Box></Grid>
                                        <Grid item><Box className={classes.psc}>ROI</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ paddingTop: 32, paddingRight: 32 }}>
                                        <CardMedia image="/in_progress.png" component="img" />
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.acceleratorsDescArea} style={{ marginTop: mobile ? 19 : 32, paddingLeft: mobile ? 27 : 32, paddingTop: mobile ? 12 : 16 }}>
                                    <Box className={classes.acceleratorsDescText}>ROI estimate to help Product Owners justify features priority.</Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item style={{ padding: mobile ? 16 : 32 }}>
                        <Paper className={classes.calculator} elevation={3} style={{ opacity: 0.5 }}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Grid item container direction="row" wrap="nowrap">
                                    <Grid item container direction="column" alignItems="flex-start" style={{ paddingTop: 32, paddingLeft: 32 }}>
                                        <Grid item><Box className={classes.psc}>TEAM</Box></Grid>
                                        <Grid item><Box className={classes.psc}>BURN</Box></Grid>
                                        <Grid item><Box className={classes.psc}>RATE</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ paddingTop: 32, paddingRight: 32 }}>
                                        <CardMedia image="/in_progress.png" component="img" />
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.acceleratorsDescArea} style={{ marginTop: mobile ? 19 : 32, paddingLeft: mobile ? 27 : 32, paddingTop: mobile ? 12 : 16 }}>
                                    <Box className={classes.acceleratorsDescText}>Month to month resources spending budget and related financial parameters.</Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item style={{ padding: mobile ? 16 : 32 }}>
                        <Paper className={classes.calculator} elevation={3} style={{ opacity: 0.5 }}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Grid item container direction="row" wrap="nowrap">
                                    <Grid item container direction="column" alignItems="flex-start" style={{ paddingTop: 32, paddingLeft: 32 }}>
                                        <Grid item><Box className={classes.psc}>LOAN TO</Box></Grid>
                                        <Grid item><Box className={classes.psc}>VALUE</Box></Grid>
                                        <Grid item><Box className={classes.psc}>CALCULATOR</Box></Grid>
                                    </Grid>
                                    <Grid item style={{ paddingTop: 32, paddingRight: 32 }}>
                                        <CardMedia image="/in_progress.png" component="img" />
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.acceleratorsDescArea} style={{ marginTop: mobile ? 19 : 32, paddingLeft: mobile ? 27 : 32, paddingTop: mobile ? 12 : 16 }}>
                                    <Box className={classes.acceleratorsDescText}>Provisionary assessment of invenstment risk before approving funds for startups.</Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item container direction="row" justify="center" alignItems="center" style={{ marginTop: 32 }}>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                    <Grid item style={{ marginLeft: 16, marginRight: 16 }}><CardMedia image="/arrow_delimeter_light.png" component="img" /></Grid>
                    <Grid item style={{ width: 'calc(50% - 47.5px)'}}><CardMedia style={{ height: 1 }} image="/delimeter_horizontal_light.png" component="img" /></Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" data-aos="slide-up" style={{ marginTop: 16, marginBottom: 32 }}>
                <Grid item style={{ width: mobile ? 345 : 951, margin: 16 }}><Box className={classes.headerText} style={{ fontSize: mobile ? 42 : 65 }}>EATING LEMONS IMPROVES YOUR PRODUCTIVITY</Box></Grid>
                <Grid item container direction="row" justify="center" alignItems="flex-start">
                    <Grid item container direction="column" justify="center" alignItems="center" style={{ width: 400, margin: 32 }}>
                        <Grid item><Box className={classes.productivityHeaderText}>2X</Box></Grid>
                        <Grid item><Box className={classes.acceleratorsHeaderText}>Success rate to finish implementation for Minimum Sellable Product</Box></Grid>
                    </Grid>    
                    <Grid item container direction="column" justify="center" alignItems="center" style={{ width: 400, margin: 32 }}>
                        <Grid item><Box className={classes.productivityHeaderText}>23%</Box></Grid>
                        <Grid item><Box className={classes.acceleratorsHeaderText}>Less management efforts overhead due to values driven model</Box></Grid>
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="center" style={{ width: 400, margin: 32 }}>
                        <Grid item><Box className={classes.productivityHeaderText}>2X</Box></Grid>
                        <Grid item><Box className={classes.acceleratorsHeaderText}>Increased chances to kick-off your product development using the platform, while others are still procrastinating</Box></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" justify="center" alignItems="center" data-aos="fade-in" style={{ marginTop: 32, marginBottom: 64 }}>
                <Grid item style={{ marginBottom: 16 }}><CardMedia style={{ width: mobile ? 350 : 700, height: 1 }} image="/delimeter_horizontal.png" component="img" /></Grid>
                <Grid item container direction="row" justify="center" alignItems="center">
                    <Grid item><Box className={classes.footerText}>© 2020 teamofteams.io</Box></Grid>
                    <Grid item style={{ marginLeft: 8 }}><a href="https://t.me/teamofteams" target="_blank"><CardMedia image="/footer-telegram-home.png" style={{ width: 30, height: 30 }} component="img" /></a></Grid>
                </Grid>
                <Grid item><Box>Los Angeles, California</Box></Grid>
            </Grid>
        </Container>
    );
}

export default HomeTwo;