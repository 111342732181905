import React from 'react';

import { CardMedia, CardContent } from '@material-ui/core';

export function ProgressBar(props) {
    let past = [];
    for (let i = 0; i < props.current; i++) {
        past.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_past.png" component="img" key={i} />);
    }

    let future = [];
    for (let i = props.current; i < props.total - 1; i++) {
        future.push(<CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_future.png" component="img" key={i} />);
    }

    return (
        <CardContent style={{ display: 'flex', paddingTop: 4, paddingBottom: 4 }}>
            { past }
            <CardMedia style={{ width: 7, height: 7, marginLeft: 2 }} image="/progress_current.png" component="img" />
            { future }
        </CardContent>
    )
}