import { API } from 'aws-amplify';

export function login(inviteId, pin, token) {
    return dispatch => {
        const myInit = {
            body: {
                invite_id: inviteId,
                pin: pin,
                token: token
            }
        };
        dispatch({ type: 'LOADING_STARTED' });
        API.post('totapi', '/login', myInit)
        .then(response => {
            if (response.error) {
                let error = response.error;
                dispatch({ type: 'LOGIN_ERROR', error });
            } else {
                let firstName = response ? response.first_name : '';
                dispatch({ type: 'LOGIN', firstName });
            }
            dispatch({ type: 'LOADING_FINISHED' });
        })
        .catch(error => {
            console.log(error);
            dispatch({ type: 'LOGIN_ERROR', error });
        });
    };
}

export function joinWaitlist(email, token) {
    return dispatch => {
        const myInit = {
            body: {
                email: email,
                token: token
            }
        };
        dispatch({ type: 'LOADING_STARTED' });
        API.post('totapi', '/join-waitlist', myInit)
        .then(response => {
            if (response.error) {
                let error = response.error;
                dispatch({ type: 'WAITLIST_ERROR', error });
            } else {
                dispatch({ type: 'JOINED_WAITLIST' });
            }
            dispatch({ type: 'LOADING_FINISHED' });
        })
        .catch(error => {
            console.log(error);
            dispatch({ type: 'WAITLIST_ERROR', error });
        });
    };
}