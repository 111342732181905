import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Grid, TextField, Button, Box, Container } from '@material-ui/core';

function Initiation() {
    const firstName = useSelector(state => state.authentication.firstName);
    const history = useHistory();

    useEffect(() => {
        if (firstName === '') {
            history.push("/");
        }
    }, [firstName]);

    return (
        <Container component="main" maxWidth="sm">
            <h1>Welcome to Team of Teams, { firstName }!</h1>
        </Container>
    );
}

export default Initiation;