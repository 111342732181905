import { combineReducers } from 'redux';

import { authentication } from './authentication';
import { psc } from './psc';
import { pte } from './pte';
import { preloader } from './preloader';
import { waitlist } from './waitlist';

const rootReducer = combineReducers({
    authentication,
    psc,
    pte,
    preloader,
    waitlist
});

export default rootReducer;