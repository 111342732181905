import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Grid, CardMedia, Paper, Box, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SendSharpIcon from '@material-ui/icons/SendSharp';

import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { submitProductDetails } from './actions';
import { Header, Footer, ResultBar } from './components';

const useStyles = makeStyles((theme) => ({
    middle: {
        backgroundColor: '#F0F0F0',
        paddingBottom: 24,
        margin: 0,
        width: '100%'
    },
    headers: {
        width: 320,
        backgroundImage: 'url("/spell_black_background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        paddingTop: 24,
        marginRight: -16
    },
    text: {
        fontFamily: 'Helvetica Neue',
        fontSize: 12
    },
    recommendationText: {
        fontFamily: 'Helvetica Neue',
        fontSize: 14
    },
    psc: {
        fontSize: 20,
        fontFamily: 'Muna'
    },
    disclaimerText: {
        fontSize: 20,
        fontFamily: 'Muna',
        fontWeight: 'bold'
    },
    labelText: {
        fontSize: 15,
        fontFamily: 'Arial'
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    },
    successRateArea: {
        backgroundColor: 'rgba(179, 224, 229, 0.58)',
        borderRadius: 6,
    },
    productDetailsArea: {
        borderRadius: 9,
        border: '1px dotted black',
        padding: 16,
        backgroundColor: '#F0F0F0',
    },
    miniHeader: {
        backgroundColor: '#D9B800',
        color: '#000',
        padding: '4px 12px 4px 12px',
        fontFamily: 'Arial',
        fontSize: 20,
        borderRadius: 5
    },
    input: {
        backgroundColor: '#C0F4FD',
        color: '#000000',
        borderRadius: 6,
        width: '275px',
        '&:hover': {
            backgroundColor: '#C0F4FD',
        },
        '&.Mui-focused': {
            backgroundColor: '#C0F4FD',
        }
    },
    error: {
        backgroundColor: '#B74956',
        padding: '8px 16px',
        width: 275,
        textAlign: 'center'
    }
}));

function Results() {
    const [productDetailsState, setProductDetailsState] = useState({
        email: '',
        productName: '',
        productValue: '',
        productProblem: '',
        displaySendButton: false
    });

    function setInput(key, value) {
        setProductDetailsState({ ...productDetailsState, [key]: value });
    }

    const[resultsError, setResultsError] = useState();

    const questionnaireResult = useSelector(state => state.psc.questionnaireResult);
    const emailSent = useSelector(state => state.psc.emailSent);
    const dispatch = useDispatch();

    useEffect(() => {
        if (validateEmail(productDetailsState.email)) {
            setProductDetailsState({ ...productDetailsState, displaySendButton: true });
        } else {
            setProductDetailsState({ ...productDetailsState, displaySendButton: false });
        }
    }, [productDetailsState.email]);

    function validateEmail(email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    function handleSendResults(e) {
        if (validateEmail(productDetailsState.email) && productDetailsState.productName && productDetailsState.productValue && productDetailsState.productProblem) {
            dispatch(submitProductDetails(questionnaireResult.id, questionnaireResult.submitted_date, productDetailsState.email, concatenateProductDescription()));
        } else {
            setResultsError('Please fill in all the fields!');
        }
    }

    function concatenateProductDescription() {
        return 'As a customer of ' + productDetailsState.productName + ' I want to ' + productDetailsState.productValue + ' so that ' + productDetailsState.productProblem;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const classes = useStyles();

    return (
        <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 32 }}>
            <Header />
            <Grid container spacing={2} direction="column" justify="flex-start" alignItems="center" className={classes.middle}>
                <Grid container item direction="column" justify="flex-start" alignItems="center" className={classes.headers}>
                    <Grid item style={{ paddingBottom: 0, paddingTop: 24 }}><Box className={classes.psc}>PRODUCT SUCCESS</Box></Grid>
                    <Grid item style={{ paddingTop: 0 }}><Box className={classes.psc}>CALCULATOR</Box></Grid>
                    <Grid item><Box className={classes.disclaimerText} style={{ marginTop: 24 }}>RESULTS*</Box></Grid>
                    <Grid item><Box style={{ fontSize: 12, fontFamily: 'Arial', marginTop: 8 }}>* can be sent to your email. Please check below</Box></Grid>
                </Grid>
                <Grid item style={{ marginRight: -16 }}>
                    <Paper className={classes.successRateArea}>
                        <Grid container direction="column" alignItems="center" style={{ width: 280 }}>
                            <Grid item style={{ paddingTop: 8 }}><Box style={{ fontSize: 50, fontFamily: 'Muna' }}>{questionnaireResult.success_rate}%</Box></Grid>
                            <Grid item><Box style={{ fontSize: 18, fontFamily: 'Muna' }}>your success probability rate</Box></Grid>
                            <Grid item style={{ padding: 8, paddingLeft: 16 }}><Box className={classes.text}>We rate under 4 dimensions and here are preliminary recommendations prior to advanced qualification process.</Box></Grid>
                        </Grid>
                    </Paper>
                </Grid>
                { questionnaireResult !== '' ? questionnaireResult.results.map(element => (<>
                    <Grid item container direction="row" justify="space-between" wrap="nowrap" alignItems="center" style={{ width: 280, paddingTop: 24, marginRight: -16, borderBottom: '1px solid #707070' }}>
                        <Grid item><Box style={{ fontSize: 28, fontFamily: 'Muna', textTransform: 'uppercase' }}>{element.category}</Box></Grid>
                        <Grid item><ResultBar rating={element.rating} /></Grid>
                    </Grid>
                    <Grid item style={{ width: 280, marginRight: -16 }}><Box className={classes.recommendationText}>{element.recommendation}</Box></Grid>
                </>)) : <Redirect to='/' /> }
                <Grid item style={{ marginRight: -16 }}>
                    <Paper className={classes.successRateArea}>
                        <Grid container direction="column" alignItems="center" style={{ width: 280, padding: 16 }}>
                            <Grid item><Box style={{ fontSize: 16 }}>You’ve earned a badge from<br />Team of Teams platform because you’ve evaluated a product idea! Save your "ToT Product Owner" status by proceeding with the "Brain Trainer" form below.</Box></Grid>
                            <Grid item style={{ marginTop: 16 }}><CardMedia image="/product_owner_badge.png" component="img" /></Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item style={{ marginRight: -16 }}>
                    <Paper className={classes.productDetailsArea}>
                        { emailSent ? 
                            <Grid container direction="column" alignItems="center" style={{ width: 280 }}>
                                <Grid item><Box style={{ fontSize: 28, fontFamily: 'Muna' }}>THANK YOU</Box></Grid>
                                <Grid item><Box>Results were sent to your email address</Box></Grid>
                            </Grid>
                        :
                            <Grid container direction="column" alignItems="center" style={{ width: 280 }}>
                                <Grid item container spacing={2} direction="column" alignItems="center" style={{ width: 280 }}>
                                    <Grid item style={{ width: 280, textAlign: 'center' }}><Paper className={classes.miniHeader} elevation={0}>Brain Trainer</Paper></Grid>
                                    <Grid item><Box style={{ fontSize: 15 }}>To get these results to email, describe what value your product delivers according to the user story structure from your <b>customer's perspective</b>. Here is the example of it for the Team of Teams platform:</Box></Grid>
                                    <Grid item style={{ paddingTop: 0 }}><Box className={classes.text} style={{ fontStyle: 'italic', fontSize: 13 }}><b>As a customer of</b> Team of Teams digital platform <b>I want to</b> form partnerships based on common professional values and use platform accelerators to lift up my product ideas <b>so that</b> I can build great products and high performing teams!</Box></Grid>
                                </Grid>
                                <Grid container direction="column" alignItems="flex-start" style={{ width: 280, paddingTop: 32 }}>
                                    <Grid item><Box className={classes.labelText}>As a customer of</Box></Grid>
                                    <Grid item>
                                        <TextField required id="productName" name="productName" variant="filled" placeholder="your product name" margin="dense"
                                            onChange={event => setInput('productName', event.target.value)} value={productDetailsState.productName}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px' }, maxLength: 64}}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}><Box className={classes.labelText}>I want to</Box></Grid>
                                    <Grid item>
                                        <TextField required id="productValue" name="productValue" variant="filled" placeholder="your value" margin="dense"
                                            onChange={event => setInput('productValue', event.target.value)} value={productDetailsState.productValue}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px' }, maxLength: 256}}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}><Box className={classes.labelText}>So that</Box></Grid>
                                    <Grid item>
                                        <TextField required id="productProblem" name="productProblem" variant="filled" placeholder="what problem resolved" margin="dense"
                                            onChange={event => setInput('productProblem', event.target.value)} value={productDetailsState.productProblem}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px' }, maxLength: 256}}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ marginTop: 16 }}><Box className={classes.labelText}>Get copy of your results</Box></Grid>
                                    <Grid item>
                                        <TextField required id="email" name="email" variant="filled" placeholder="your email" margin="dense"
                                            onChange={event => setInput('email', event.target.value)} value={productDetailsState.email}
                                            inputProps={{ style: { textAlign: 'left', fontSize: 15, padding: '10px 10px 10px' }, maxLength: 64}}
                                            InputProps={{
                                                classes: {
                                                    root: classes.input 
                                                },
                                                disableUnderline: true,
                                                endAdornment: productDetailsState.displaySendButton && (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={handleSendResults}><SendSharpIcon style={{ fill: 'black'}} /></IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    { resultsError &&
                                        <Grid item style={{ marginTop: 8 }}>
                                            <Paper className={classes.error} elevation={3}><Box style={{color: 'white'}}>{resultsError}</Box></Paper>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <Footer />
        </Container>
    );
}

export default Results;
