const initialState = { 
    inviteId: '',
    pin: '',
    firstName: '',
    error: ''
}

export function authentication(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN':
            return { firstName:  action.firstName };
        case 'LOGIN_ERROR':
            return { error: action.error };
        default:
            return state;
    }
}