import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Grid, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    top: {
        height: 150,
        margin: 0,
        width: '100%'
    },
    booster: {
        backgroundColor: '#D9B800',
        color: '#fff',
        padding: '4px 12px 4px 12px',
        fontFamily: 'Helvetica Neue',
        borderRadius: 5
    }
}));

export function Header() {
    const classes = useStyles();

    return (
        <Grid container spacing={2} direction="column" justify="center" alignItems="center" component="header" className={classes.top}>
            <Grid item>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <CardMedia image="/logo-header.png" component="img" />
                </Link>
            </Grid>
            <Grid item style={{ paddingTop: 4 }}>
                <Paper className={classes.booster} elevation={0}>ToT Accelerator</Paper>
            </Grid>
        </Grid>
    )
}