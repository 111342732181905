const initialState = { 
    questionnaireResult: '',
    emailSent: false
}

export function psc(state = initialState, action) {
    switch (action.type) {
        case 'RESET_QUESTIONNAIRE':
            return { questionnaireResult: '', emailSent: false }
        case 'SUBMIT_QUESTIONNAIRE':
            return { questionnaireResult: action.questionnaireResult };
        case 'SUBMIT_PRODUCT_DETAILS':
            return { ...state, emailSent: true };
        case 'QUESTIONNAIRE_ERROR':
            return { error: action.error };
        default:
            return state;
    }
}