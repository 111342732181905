import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, Grid, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    bottom: {
        margin: 0,
        width: '100%',
        paddingTop: 24
    },
    footerText: {
        color: '#fff',
        fontFamily: 'Arial',
        textAlign: 'center'
    }
}));

export function Footer() {
    const classes = useStyles();

    return (
        <Grid item container spacing={1} className={classes.bottom} direction="row" justify="center" alignItems="center">
            <Grid item><Box className={classes.footerText}>© 2020 teamofteams.io</Box></Grid>
            <Grid item><a href="https://t.me/teamofteams" target="_blank"><CardMedia image="/footer-telegram.png" style={{ width: 30, height: 30 }} component="img" /></a></Grid>
        </Grid>
    )
}