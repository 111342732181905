import { API } from 'aws-amplify';

export function submitTimelineEstimate(estimatorFormState, token) {
    return dispatch => {
        const myInit = {
            body: {
                estimator_form: estimatorFormState,
                token: token
            }
        };
        dispatch({ type: 'LOADING_STARTED' });
        API.post('totapi', '/estimate', myInit)
        .then(response => {
            if (response.error) {
                let error = response.error;
                dispatch({ type: 'TIMELINE_ERROR', error });
            } else {
                let timelineResult = response ? response.timeline_result : '';
                dispatch({ type: 'SUBMIT_ESTIMATE_FOR_TIMELINE', timelineResult });
            }
            dispatch({ type: 'LOADING_FINISHED' });
        })
        .catch(error => {
            console.log(error);
            dispatch({ type: 'TIMELINE_ERROR', error });
        });
    };
}

export function submitTimelineProductDetails(id, submittedDate, email, productDescription) {
    return dispatch => {
        const myInit = {
            body: {
                id: id,
                submitted_date: submittedDate,
                email: email,
                product_description: productDescription
            }
        };
        dispatch({ type: 'LOADING_STARTED' });
        API.put('totapi', '/estimate', myInit)
        .then(response => {
            if (response.error) {
                let error = response.error;
                dispatch({ type: 'QUESTIONNAIRE_ERROR', error });
            } else {
                dispatch({ type: 'SUBMIT_TIMELINE_PRODUCT_DETAILS' });
            }
            dispatch({ type: 'LOADING_FINISHED' });
        })
        .catch(error => {
            console.log(error);
            dispatch({ type: 'QUESTIONNAIRE_ERROR', error });
        });
    };
}